import React, { useEffect, useState } from 'react';

/* eslint-disable */
declare var grovefy: any; // Declare global realtime communication

function Client() {
  const [formData, setFormData] = useState({});
  const [grovefyInitialized, setGrovefyInitialized] = useState(false);
  const [messages, setMessages] = useState([
    {
      author: 'init', 
      message: 'Hola'
    },
  ]);
  /*const config = {
    group: 'oficina_12',
    from: 'customer_2',
    to: 'support', // ['superadmin'] / ['info@example.com']  / ['123456'] / ['tablet', 'iphone']
    data: {
      function: '',
      parameters: {}
    }
  };*/

  const getToken = () => {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var time = today.getTime();
    if(dd<10) {
      dd='0'+dd;
    } 

    if(mm<10) {
      mm='0'+mm;
    } 
    today = yyyy+'-'+mm+'-'+dd+'-'+time;
    //return today;
    return time;
  }

  const getCustomerCookie = () => {
    const grovefy_customer_id = grovefy.getCookie('grovefy_customer_id');
    if(!grovefy_customer_id) {
      grovefy.setCookie('grovefy_customer_id', 'customer_'+getToken());
    }
    return grovefy_customer_id;
  }

  const getToCookie = () => {
    const grovefy_to_id = grovefy.getCookie('to');
    if(!grovefy_to_id) {
      grovefy.setCookie('to', 'support');
    }
    return grovefy_to_id;
  }

  const config = {
    group: 'oficina_12',
    from: getCustomerCookie(),
    to: getToCookie(), // ['superadmin'] / ['info@example.com']  / ['123456'] / ['tablet', 'iphone']
    data: {
      function: 'initializeConnection',
      parameters: {}
    }
  };

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  useEffect(() => {
    if(grovefyInitialized===false) {
      grovefy.init(config);
      setTimeout(() => {
        const data = {
          function: 'initialize',
          parameters: {
            grovefy_customer_id: getCustomerCookie()
          }
        };
        grovefy.send(data);
      }, 2000);

      setGrovefyInitialized(true);
    }
  }, []);

  const handleSendMessage = () => {
    const data = {
      function: 'chat',
      parameters: { 
        'message': formData.message
      }
    };
    grovefy.send(data);
    // Message screen
    const new_message = {
      author: config.from,
      message: formData.message
    };
    addMessage(new_message);
  }

  const addMessage = (new_message) => {
    setMessages(messages.concat(new_message));
    // Move to bottom of .messages
    setTimeout(() => {
      const elem = document.querySelector(".messages");
      elem.scrollTo(0, elem.scrollHeight);
    }, 200);
  }

  const chat = (from, data_received) => {
    const new_message = {
      author: from,
      message: data_received.message
    };
    addMessage(new_message);
  }

  grovefy.receive = (data_received) => {
    const { group, from, to, data } = data_received;

    /*console.log('group: ', group);
    console.log('from: ', from);
    console.log('to', to);*/
    switch(data.function) {
      case 'chat':{
        chat(from, data.parameters);
        break;
      }
      default: {
        // console.log('Default: data: ', data);
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      setTimeout(() => {
        setFormData({
          ...formData,
          ['message']: ''
        })        
      }, 100);
    }
  }

  const handleRoom = (callType) => {
    const roomLink = `http://localhost:8081/room?group=${config.group}&from=${config.from}&to=${config.to}&call=${callType}`;
    window.open(roomLink);
    const roomLinkTo = `http://localhost:8081/room?group=${config.group}&from=${config.to}&to=${config.from}`;
    const data = {
      function: 'openRequestRoom',
      parameters: {
        roomLink: roomLinkTo
      }
    };
    grovefy.send(data);
  }

  return (
    <div>
      <div className='chat-wrapper'>
        {/*<header>Atención al cliente</header>*/}
        <div className='content'>
          <div className='messages-box'>

            <div id='messages' className='messages'>
              <div className='messages-items'>
                {messages.map((item) => {
                  return (
                    <div className={(item.author===config.from) ? 'message-item me' : 'message-item'}>
                      <div className='avatar'>
                        <div className='icon-avatar'></div>
                      </div>
                      <div className='bubble'>
                        <div className='author'>{item.author}</div>
                        <div className='message'>{item.message}</div>
                      </div>
                    </div>)
                })}
              </div>
            </div>

          </div>
          <div className='messages-side'>
            Links
          </div>
        </div>
        <div className='bottom'>
          <div className='bottom-header'>
            <div className='btn-group'>
              <button className='btn-circle' onClick={()=>handleRoom('share-screen')}><div className='icon-share-screen'></div></button>
              <button className='btn-circle' onClick={()=>handleRoom('voice-call')}><div className='icon-voice-call'></div></button>
              <button className='btn-circle' onClick={()=>handleRoom('video-call')}><div className='icon-video-call'></div></button>
            </div>
          </div>
          <div className='message-input-wrapper'>
            <textarea className='message-input' name='message' value={formData.message} placeholder={'Mensaje'} onChange={handleInputChange} onKeyDown={handleKeyDown}></textarea>
            <button className='btn message-send' onClick={()=>handleSendMessage()}>
              <div className='icon-send'></div>
            </button>
          </div>

        </div>
        <footer>
          <div>© 2023 <a href='https://rapimeet.com' target='_blank'>Rapimeet</a>, Todos los derechos reservados</div>
        </footer>
      </div>
    </div>
  );
}

export default Client;