import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { JS } from 'grovefy-ui-pack';
import './SideMenu.css';
// import { Icon } from 'ui-pack-bdp';

function SideMenu() {
  const { device } = useWindowDimensions();
  const session = JS.getSession();
  const [showSideMenu, setShowSideMenu] = useState(false);

  useEffect(()=>{
    // console.log('session: ', session);
  });

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    let device = 'desktop';

    if (width < 768) {
      device = 'mobile';
    } else {
      device = 'desktop';
    }
    return {
      device
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  function changeSideMenu(visible: boolean) {
    setShowSideMenu(visible);
  }

  if ((device === 'mobile') && (!showSideMenu)) {
    return (
      <div className='side-menu-btn-open' onClick={() => changeSideMenu(true)}>
        <div className='icon-menu'></div>
      </div>
    )
  }

  return (
    <div>
      <div className={`${device} bg-side-menu`} onClick={() => changeSideMenu(false)}></div>
      <div className={`side-menu ${device}`}>
        <div>
          <div className='side-menu-btn-close' onClick={() => changeSideMenu(false)}><div className='icon-back'></div></div>
            <div className='logo-wrapper'>
              <i className='logo'></i>
            </div>
            <div className='menu'>
              <div className='item'>
                <Link to='/dashboard/' className='menu-icon-link'>
                  <div className='icon-meet'></div>
                  <div className='content'>Meetings</div>
                </Link>
              </div>
              <div className='item'>
                <Link to='/dashboard/calendar' className='menu-icon-link'>
                  <div className='icon-calendar'></div>
                  <div className='content'>Calendario</div>
                </Link>
              </div>
              <div className='item'>
                <Link to='/dashboard/monetize' className='menu-icon-link'>
                  <div className='icon-monetize'></div>
                  <div className='content'>Ingresos</div>
                </Link>
              </div>{/*
              <div className='item'>
                <Link to='/dashboard/statistics' className='menu-icon-link'>
                  <div className='icon-statistics'></div>
                  <div className='content'>Estadísticas</div>
                </Link>
              </div>*/}
              <div className='item'>
                <Link to='/dashboard/installation' className='menu-icon-link'>
                  <div className='icon-installation'></div>
                  <div className='content'>Compartir</div>
                </Link>
              </div>
              <div className='item'>
                <Link to='/dashboard/profile' className='menu-icon-link'>
                  <div className='icon-profile'></div>
                  <div className='content'>Perfil</div>
                </Link>
              </div>
            </div>
          </div>
          <div className='rapimeet-link-wrapper'>
            {/*<a href='/dashboard/profile'><div className='logo-company'>Logo</div></a>*/}
            {session.custom.channels.map((item,index)=>{
              const rapimeet_link = `https://rapimeet.com/${item.channel_linkname}`;
              return (<div key={index} >
                <div className='header'>Comparte tu enlace Rapimeet en tus redes sociales, sitio web o donde quieras...</div>
                <div className='align-right'><button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(rapimeet_link)}>Copiar</button> </div>
                <input className='input-rapimeet' value={`https://rapimeet.com/${item.channel_linkname}`}/>
              </div>)
            })}
          </div>
          <div className='bottom'>
            <Link to='/close' className='btn-session'>
              <div className='icon-logout'></div>
              <div className='content'>Cerrar sesión</div>
            </Link>
          </div>
        </div>
    </div>
  );
}

export default SideMenu