import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { Provider } from 'react-redux';
import store from './store/Store';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_es from "./translation/es/common.json";
import common_en from "./translation/en/common.json";
import 'grovefy-ui-pack/dist/grovefy-ui-pack.css';
import './assets/css/styles.css';

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'es',                              // language to use
  resources: {
    en: {
      common: common_en // 'common' is our custom namespace
    },
    es: {
      common: common_es
    },
  },
});
// '../node_modules/rb-ui-pack/dist/rb-ui-pack.css';
// DONE redux
// DONE Mixpanel
// DONE Typescript
// DONE API
// Location / City / Activar geolocacion
// Transaction
// Fix API scalability
// Prettier
// Eslint

// Testing
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
