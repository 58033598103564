import React, { useEffect, useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'grovefy-ui-pack';
import { amountToStripeFormat, stripeAmountToDecimal } from '../../../lib/Lib';
import { request, ContentTypes } from '../../../store/api/api';
import { Link, useParams } from 'react-router-dom';

/*
const amountToStripeFormat = (amount) => {
  let amountConverted = amount.toString();
  if (amountConverted.length<3) {
    amountConverted = amountConverted+'00';
  }
  // Remove commas, dots, others.
  amountConverted = amountConverted.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
  console.log('amountToStripeFormat: amountConverted: ',amountConverted);
  return amountConverted;
}*/

const CheckoutForm = (props) => {
  const { urlReferer, payment } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [ clientSecret, setClientSecret ] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const { amount, payment_to_channel, payment_to_email } = payment;
  const amount_stripe = amountToStripeFormat(amount);
  const group = payment_to_channel;
  const [paymentStatus,setPaymentStatus] = useState(false);
  const [paymentIntentIdResult, setPaymentIntentIdResult] = useState();
  const [formAvailable, setFormAvailable] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: ''
  });
  const [checked, setChecked] = React.useState(false);

  useEffect(()=>{
  })

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function sendEmail(data) {
    const {
      id,
      amount,
      payment_method_id,
      email,
      firstname,
      lastname,
      payment_to_channel,
      payment_to_email,
      payment_amount_net
    } = data;

    const payload = {
      "to": email.toLowerCase(),
      "subject": "Pago realizado correctamente",
      "message": "<h2>Pago registrado</h2>"+
      "<p>¡Hola "+firstname+"!</p>"+
      "<p>Tu compra ha sido entregada al responsable del meeting.</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<h3>Datos de pago</h3>"+
      "<p>Destinatario: <span style='font-weight: 600;'>https://rapimeet.com/"+payment_to_channel+"</span></p>"+
      "<p>Email de destinatario: <span style='font-weight: 600;'>"+payment_to_email+"</span></p>"+
      "<p>ID de pago: <span style='font-weight: 600;'>"+payment_method_id+"</span></p>"+
      "<p>Monto abonado: <span style='font-weight: 600;'>€"+stripeAmountToDecimal(amount)+"</span></p>"+
      "</div>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<a style='background-color: #0057FF; color: #FFFFFF; font-weight: 600; border-radius: 30px; padding: 10px 40px; text-decoration: none;' href='"+urlReferer+'&pi='+JS.encodeString(payment_method_id)+"'>Ir a la meeting</a></div>"
    };
    const endpoint = `/sendHTML`;

    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setLoading(false);
      if(result) {
        setPaymentStatus(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email, firstname, lastname, group } = formData;
    if(email!=='' && firstname!=='' && lastname !=='') {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        setLoading(true);
        const { id } = paymentMethod;
        const amount_decimal =  parseFloat(stripeAmountToDecimal(amount_stripe));
        const payment_amount_net_decimal = amount_decimal-(amount_decimal*9)/100;
        const payment_amount_net = amountToStripeFormat(payment_amount_net_decimal.toString().replace('.', ','));
        const payload = {
          id,
          amount: amount_stripe,
          payment_method_id: id,
          email,
          firstname,
          lastname,
          payment_to_channel,
          payment_to_email,
          payment_amount_net
        };
        const endpoint = `/payment_stripe`;

        try {
          const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
          const {id, status} = res.data.data
          if (id && status === 'succeeded') {
            // Handle successful payment here
            setPaymentIntentIdResult(id);
            sendEmail(payload);
          } else {
            // Handle unsuccessful, processing, or canceled payments and API errors here
            // console.log('unsuccessful');
          }

          elements.getElement(CardElement).clear();
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      }
    }
  };

  const goToMeeting = () => {
    window.location.href=urlReferer+'&pi='+JS.encodeString(paymentIntentIdResult);
  }

  if (paymentStatus) {
    return (
      <div>
        <Row>
          <Col>
            <Notification className='success'>¡Felicidades! El pago se ha realizado correctamente, ahora podrás continuar con tu reunión online.</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="primary" label='Volver al meeting' action={()=>goToMeeting()}/>
          </Col>
        </Row>
      </div>
    )
  }

  const validateForm = () => {
    const { email, firstname, lastname, group } = formData;
    if(email!=='' && firstname!=='' && lastname !=='' && checked) {
      return true;
    }
    return false;
  }

  return (
    <form onSubmit={(e) =>handleSubmit(e)}>
      {/* Product Information */}
        {(notification.status) ? (
          <Row>
            <Col>
              <Notification className={notification.className}>{notification.message}</Notification>
            </Col>
          </Row>) : null
        }
        <Row>
          <Col>
            <Notification className='alert'>
              Importante: Asegúrese de no estar compartiendo pantalla al ingresar los datos de su tarjeta. Rapimeet no asume responsabilidad por cualquier uso indebido o daño que pueda ocurrir a través de esta plataforma.
            </Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            Destino: https://rapimeet.com/{group}
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='text' name='firstname' value={formData.firstname} placeholder={'Nombre'} onChange={handleInputChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='text' name='lastname' value={formData.lastname} placeholder={'Apellido'} onChange={handleInputChange}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='email' name='email' value={formData.email} placeholder={'Email'} onChange={handleInputChange}/>
          </Col>
        </Row>
        <Row>
          <Col><H3>Total</H3></Col>
          <Col className='align-right'><H3><span className='text-gray plan-strikethrough space'>€{stripeAmountToDecimal(amount_stripe)}</span></H3></Col>
        </Row>
        <Row>
          <Col>
            <input
              type="checkbox"
              className='space'
              checked={checked}
              onChange={handleChange} 
            /> Acepta los <Link to={'/terms'} target="_blank" rel="noreferrer">Términos y condiciones</Link> y <Link to={'/terms'} target="_blank" rel="noreferrer">Política de privacidad</Link>
          </Col>
        </Row>
      <Row>
        <Col>
          <CardElement className="inp"/>
        </Col>
      </Row>
      {
        validateForm() ? (
          <button disabled={!stripe} className="btn primary">
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Pagar ahora'
            )}
          </button>
        ) 
        : (
          <button className="btn disabled">
            Pagar ahora
          </button>
        )
      }
      
    </form>
  );
};

export default CheckoutForm;
/*

import {PaymentElement} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  return (
    <form>
      <PaymentElement />
      <button>Submit</button>
    </form>
  );
};

export default CheckoutForm;

*/