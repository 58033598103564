import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H3, Tabs, Tab, JS } from 'grovefy-ui-pack';
import MyIncome from './tabs/myIncome/MyIncome';
import MyFee from './tabs/myFee/MyFee';
import Withdrawals from './tabs/withdrawals/Withdrawals';
import PaymentSettings from './tabs/paymentSettings/PaymentSettings';

function Monetize() {
  const session = JS.getSession();
  const {account_role} = session.account;

  useEffect(()=>{
  })

  return (
    <div>
      <Section>
        <H3>Ingresos</H3>
      </Section>
      {account_role==='super_admin' ? (
        <div>
          <Section>
            <Row>
              <Col>
                <Tabs>
                  <Tab data='Ingresos'>
                    <MyIncome/>
                  </Tab>
                  <Tab data='Configuracion'>
                    <PaymentSettings/>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Section>
        </div>) : (
          <PaymentSettings/>
      )}
    </div>
  )
}

export default Monetize;