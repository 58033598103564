import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H5, Input, Button, Notification, Loading, JS, LineDivisor } from 'grovefy-ui-pack';
import * as JSLib from '../../../../../lib/Lib';
import { request, ContentTypes } from '../../../../../store/api/api';
import daysSessionDefault from '../../../../../store/mock/daysSession.json';
import { useTranslation } from "react-i18next";

function EditSchedule() {
  const {t} = useTranslation('common');
  const session = JS.getSession();
  const {account_email, account_firstname, account_lastname} = session.account;
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [daysSession, setDaysSession ] = useState(daysSessionDefault);
  const [formData, setFormData] = useState({time_start: '09:00', time_end: '09:00'});
  const [showForm, setShowForm]= useState();
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState(session.custom.channels[0]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [calendarStatus, setCalendarStatus] = useState(false);

  useEffect(()=> {
    if(loading===false) {
      getEndpointScheduleCalendar();
    }
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({...formData, [name]: value} );
  };

  const handleInputChangeDay = (e) => {
    const { name, value } = e.target;
    setFormData({
      "time_start": value,
      "time_end": value
    })

  }

  const getEndpointScheduleCalendar = async () => {
    const data = {
      channel_id: channel.channel_id
    };
    const endpoint = `/schedule_calendar`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const scheduleCalendarResult = res.data.data;
      getEndpointChannel();
      if(scheduleCalendarResult===undefined) {
        createEndpointScheduleCalendar();
      } else {
        setDaysSession(scheduleCalendarResult.calendar_sessions);
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createEndpointScheduleCalendar = async () => {
    const payload = {
      channel_id: channel.channel_id,
      calendar_sessions: daysSession
    };
    const endpoint = `/schedule_calendar/create`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
    } catch (error) {
      console.log(error);
    }
  };

  const setEndpointScheduleCalendar = async () => {
    setLoadingSubmit(true);
    const payload = {
      channel_id: channel.channel_id,
      calendar_sessions: daysSession
    };
    const endpoint = `/schedule_calendar/update`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      setNotification({
        status: true,
        style: 'success',
        message: 'Los datos se han guardado correctamente.'
      });
      setLoadingSubmit(false);

    } catch (error) {
      setLoadingSubmit(false);
      console.log(error);
    }
  };

  const setEndpointCalendarStatus = async (calendar_status) => {
    const payload = {
      channel_id: channel.channel_id,
      calendar_status: calendar_status
    };
    const endpoint = `/channel/update`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      setCalendarStatus(calendar_status);
    } catch (error) {
      setLoadingSubmit(false);
      console.log(error);
    }
  };

  const getEndpointChannel = async () => {
    const data = {
      channel_linkname: channel.channel_linkname
    };
    const endpoint = `/channel`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const channelResult = res.data.data;
      // console.log('getEndpointChannel: channelResult: ', channelResult);
      setCalendarStatus(channelResult.calendar_status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitAddTime = (e, day_id, index) => {
    e.preventDefault();
    let daysSessionUpdate = JSON.parse(JSON.stringify(daysSession));
    daysSessionUpdate[index].day_time_sessions.push(formData);
    setFormData({
      time_start: '',
      time_end: ''
    })
    const daysSessionOrderer = orderDaysSession(daysSessionUpdate);
    setDaysSession(daysSessionOrderer);
    setShowForm();
  }

  const handleRemoveTime = (index, time_index) => {
    let daysSessionUpdated = JSON.parse(JSON.stringify(daysSession));
    const dayTimeSessions = JSON.parse(JSON.stringify(daysSessionUpdated[index].day_time_sessions));
    let dayTimeSessionsUpdated = []
    dayTimeSessions.forEach((dayTimeSessionItem, indexItem) => {
      if(indexItem!==time_index) {
        dayTimeSessionsUpdated.push(dayTimeSessionItem);
      }
    });
    daysSessionUpdated[index].day_time_sessions=dayTimeSessionsUpdated;
    setDaysSession(daysSessionUpdated);
  }

  const handleSaveSchedule = () => {
    setEndpointScheduleCalendar();
  }

  const handleChangeCalendarStatus = (calendar_status) => {
    setEndpointCalendarStatus(!calendar_status);
  }  

  const renderFormAddTime = (day_id, index) => {
    return (
      <form onSubmit={(e)=>handleSubmitAddTime(e, day_id, index)}>
        <Row className='mb-column'>
          <Col className='group'>
            Comienzo de sesión
            <input className='inp time' id="time_start" name="time_start" type="time" value={formData.time_start} required onChange={(e)=>handleInputChangeDay(e)}/>
          </Col>
          <Col className='group'>
            Fin de sesión
            <input className='inp time' type="time" id="time_end" name="time_end" min={formData.time_start} value={formData.time_end} defaultValue={formData.time_end} required onChange={(e)=>handleInputChange(e)}/>
          </Col>
          <Col>
            <Button type='submit' label='Agregar sesión' action={()=>null} />
          </Col>
        </Row>
      </form>
    )
  }

  const renderDaySession = (dayItem, index) => {
    const {day_id, day_name, day_time_sessions } = dayItem;

    return (
      <div>
        <Row className='mb-column margin-top'>
          <Col className='w-20'>
            <H5>{t(`days.${day_name}`)}</H5>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className='session-time-items'>
                  {day_time_sessions.map((dayTimeSessionItem, time_index)=> {
                    if (dayTimeSessionItem !== undefined ) {
                      return <div className='session-time-item'>{dayTimeSessionItem.time_start} a {dayTimeSessionItem.time_end} <div onClick={()=>handleRemoveTime(index, time_index)} className='btn-remove'><div className='icon-remove'></div></div></div>
                    }
                  })}
                </div>
              </Col>
            </Row>
            {showForm===day_id ? renderFormAddTime(day_id, index): <Button className='small' label='Agregar sesión' action={()=>setShowForm(day_id)} />}
          </Col>
        </Row>
        <LineDivisor />
      </div>
    );
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  const orderDaysSession = (daysSessionToOrder) => {
    const daysSessionOrder = daysSessionToOrder.map((item)=>{
      const listSessionsOrder = item.day_time_sessions.sort(dynamicSort("time_start"));
      return {...item, day_time_sessions: listSessionsOrder}
    });
    return daysSessionOrder;
  }

  return (
    <div className='tables'>
      <Section>
        <Row>
          <Col>
            <H5>Editar disponibilidad</H5>
          </Col>
        </Row>
          <div>
            <Row>
              <Col>
                Define sesiones de manera individual por cada día (Ej: Lunes 10:00 a 11:00hs, 11:00 a 12:00hs y 12:00 a 13:00hs).
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="checkbox"
                  checked={calendarStatus}
                  className='space'
                  onClick={()=>handleChangeCalendarStatus(calendarStatus)}
                />
                Habilitar "Agendar reunión"
              </Col>
            </Row>
            <LineDivisor />
            {
              daysSession.map((dayItem, index)=> renderDaySession(dayItem, index))
            }
            {(notification.status===true) ? (
              <Row className='margin-top'>
                <Col>
                  <Notification className={notification.style}>{notification.message}</Notification>
                </Col>
              </Row>) : null
            }
            <Row className='margin-top'>
              <Col>
                <Button className='primary' label='Guardar' loading={loadingSubmit} action={handleSaveSchedule}/>
              </Col>
            </Row>
          </div>
      </Section>
    </div>
  )
}

export default EditSchedule;
