import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setItems } from '../../../store/items/ItemsActions';
// import { getStatus } from '../../../store/api/api';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Input, H3, Notification, Button } from 'grovefy-ui-pack';
import { tierPrices, stripeAmountToDecimal } from '../../../lib/Lib';
//import { PREFIX_URL } from '../../../store/constants';

function SignUp(props) {
  const { account_tier } = useParams();
  const { t } = useTranslation('common');
  // const { items } = useSelector((state: any) => state.default.items)
  // const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const prices = tierPrices();
  const tier_selected = prices.findIndex(item => item.tier_id === account_tier) > -1 ? account_tier : 'starter';
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    repeat_password: '',
    tier_selected: tier_selected
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [loading, setLoading] = useState(false);

  useEffect(() => {});

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  const handleSelectChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function createAccount(event) {
    event.preventDefault();
    setLoading(true);
    if((formData.firstname==='') ||
      (formData.lastname==='') ||
      (formData.email==='') ||
      (formData.repeat_email==='') || ((formData.email!=='') && (formData.repeat_email!=='') && (formData.repeat_email!==formData.email)) || 
      (formData.password==='') ||
      (formData.repeat_password==='')) {
      setNotification({
        status: true,
        className: 'danger',
        message: 'Debe completar todos los campos.'
      });
      if (formData.repeat_email!==formData.email) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Los campos "Email" y "Repetir Email" deben ser iguales.'
        });
      }
      setLoading(false);
    } else {
      setNotification({
        status: false,
        className: '',
        message: ''
      });
      if(!checked) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Debe aceptar los términos y condiciones.'
        });
        setLoading(false);
      } else {
        const data = {
          "account_email": formData.email.toLowerCase(),
          "account_password": formData.password,
          "account_firstname": formData.firstname,
          "account_lastname": formData.lastname,
          "account_role": "customer",
          "account_tier": formData.tier_selected
        };
  
        const endpoint = `/account/create`;
        try {
          const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
          const { result } = res.data;

          if (result) {
            // setAccountCreated(true);
            //loginAccount({email: formData.email, password: formData.password});
            sendEmail();
          } else {
            if(res.data.error) {
              setNotification({
                status: true,
                className: 'danger',
                message: 'El email ya existe en nuestra base de datos.'
              });
            } else {
              setNotification({
                status: true,
                className: 'danger',
                message: 'Error al ingresar los datos.'
              });
            }
            //setLoading(false);
          }
        } catch (err) {
          console.log(err)
          setNotification({
            status: true,
            className: 'danger',
            message: 'Error al ingresar los datos.'
          });
          setLoading(false);
        }
      }
    }
  }

  async function sendEmail() {
    const payload = {
      "to": formData.email.toLowerCase(),
      "subject": "Registro de usuario",
      "message": "<h2>¡Bienvenido a Rapimeet!</h2>"+
      "<p>¡Hola "+formData.firstname+"!</p>"+
      "<p>Ahora podrás comunicarte con tus clientes a través de meetings al instante. Al hacer click en tu enlace Rapimeet podrán llamar, compartir pantalla, realizar pagos y más."+
      "<p>Comienza ahora, crea tu enlace para recibir Rapimeets y publicalo en tus redes sociales, website o donde quieras...</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<a style='background-color: #0057FF; color: #FFFFFF; font-weight: 600; border-radius: 30px; padding: 10px 40px; text-decoration: none;' href='https://rapimeet.com'>Ir a Rapimeet</a></div>"
    };

    const endpoint = `/sendHTML`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setLoading(false);
      if(result) {
        window.location.href = `/login`;
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div>
      <Section maxWidth='small' className='box-center'>
        <Row>
          <Col><H3>Crear cuenta</H3></Col>
        </Row>
        <form onSubmit={ e =>createAccount(e)}>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }

          <div>
            <Row>
              <Col>
                <select className='inp' name='tier_selected' onChange={(event)=>handleSelectChange(event)}>
                  {prices.map((item) => {
                    return (<option value={item.tier_id} selected={tier_selected===item.tier_id ? true : false}>{item.tier_name} (€{stripeAmountToDecimal(item.tier_price)} mes)</option>)
                  })
                  }
                </select>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <Input type='text' name='firstname' value={formData.firstname} placeholder={t('sign_up.firstname')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='text' name='lastname' value={formData.lastname} placeholder={t('sign_up.lastname')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='email' name='email' value={formData.email} placeholder={t('sign_up.email')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='email' name='repeat_email' value={formData.repeta_email} placeholder={'Repetir email'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='password' name='password' value={formData.password} placeholder={t('sign_up.password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='password' name='repeat_password' value={formData.repeat_password} placeholder={t('sign_up.repeat_password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='check-wrapper'>
                <input
                  type="checkbox"
                  className='space'
                  checked={checked}
                  onChange={handleChange} 
                /> 
                <div className='check-label'>{t('sign_up.accept_terms_conditions')} <Link to={'/terms'} target="_blank" rel="noreferrer">Términos y condiciones</Link> y <Link to={'/privacy'} target="_blank" rel="noreferrer">Política de privacidad</Link></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type='submit' label={t('sign_up.sign_up')} className='primary' loading={loading} action={()=>null} />
            </Col>
          </Row>
        </form>
        <Row>
          <Col>
            {t('sign_up.do_you_have_account')} <Link to='/login'>{t('sign_up.login')}</Link>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default SignUp;