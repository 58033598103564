import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, JS } from 'grovefy-ui-pack';
import './PricesList.css';
import { tierPrices, stripeAmountToDecimal } from '../../lib/Lib';

function PricesList() {
  const prices = tierPrices();
  useEffect(()=>{
  });

  return (
    <div>
      <Row>
        <Col>
          <div className='prices-items'>
            {prices.map((item)=>{
              const recommended= item.tier_id==='professional' ? 'recommended' : null;
              const price = stripeAmountToDecimal(item.tier_price).split(',');
              return (<div className={`item ${recommended}`}>
                <div className='title'>{item.tier_name}</div>
                <div className='prices'>€{price[0]}<span className='decimal'>{price[1]}</span> mes</div>
                <div className='description'>{item.tier_description}</div>
                <div className='features'>
                  <ui>
                    {item.tier_features.map((feature)=>(
                      <li>{feature}</li>
                    ))}
                  </ui>
                </div>
                <div className='bottom'>
                  <a href={`/signUp/${item.tier_id}`}>
                    <Button className='btn-large primary' label='Registrate ahora' action={()=>null}></Button>
                  </a>
                </div>
              </div>
              )     
            })
            }
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PricesList