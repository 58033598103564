import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H3, Table, Thead, Tr, Th, Tbody, Td, Tab, JS, Button } from 'grovefy-ui-pack';
import {
  useStripe,
} from "@stripe/react-stripe-js";
import { request, ContentTypes } from '../../../../../store/api/api';
import * as JSLib from '../../../../../lib/Lib';

function MyIncome() {
  const session = JS.getSession();
  const {account_email, account_firstname, account_lastname} = session.account;
  //const stripe = useStripe();
  const [payments, setPayments ] = useState();

  useEffect(()=>{
    if(!payments) {
      getPayments();
    }
  })

  const getPayments = async () => {
    const data = {
      payment_to_email: account_email
    };
    const endpoint = `/payment_stripe/list`;

    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      // const { data } = await axios.post("http://localhost:3020/get_payments", payload);
      const paymentsResult = res.data.data;
      const paymentsParser = paymentsResult.map((item)=> {
        const amount = JSLib.stripeAmountToDecimal(item.amount);
        const currency = JSLib.stripeCurrency(item.currency);
        const created = JSLib.timestampToDate(item.created);
        const amount_net = (item.metadata && item.metadata.payment_amount_net) ? JSLib.stripeAmountToDecimal(item.metadata.payment_amount_net) : '0,00';
        const amount_paid = (item.metadata && item.metadata.payment_paid) ? item.metadata.payment_paid : '-';

        const dataItem = {
          amount,
          amount_net,
          currency,
          amount_paid,
          receipt_email: item.receipt_email,
          created
        }
        return dataItem;
      });
      setPayments(paymentsParser);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className='table-wrapper'>
        <Section>
          <Row>
            <Col>
              Al solicitar ingresos se destinara a tu cuenta de Rapimeet. Cuando lo desees podras solicitar retirar tus ingresos a tu Cuenta Bancaria. Tengase en cuenta que cada retiro tiene un coste adicional. 
            </Col>
          </Row>
          <Row>
            <Col className='mb-0'>
              <Button className='btn-small' label='Retirar a cuenta bancaria' action={()=>console.log('retirar')}/>
            </Col>
          </Row>          
        </Section>

        <div className='table-scroll'>
          <Table>
            <Thead>
              <Tr>
                <Th>Email</Th><Th>Ingreso</Th><Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments ? payments.map((item, index)=>{
                const {amount_net, currency, receipt_email, amount_paid, created} = item;
                const amount_paid_name = (amount_paid==='paid') ? 'Pagado' : 'No pagado';
                return (
                  <Tr key={index}>
                    <Td>{receipt_email}</Td><Td>{currency}{amount_net} ({amount_paid_name})</Td><Td>{created.toString()}hs</Td>
                  </Tr>
                )
              }): null}
            </Tbody>
          </Table>          
        </div>
      </div>
    </div>
  )
}

export default MyIncome;