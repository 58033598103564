import React, { useEffect, useState } from 'react';
import { Table, Thead, Tr, Th, Tbody, Td, JS } from 'grovefy-ui-pack';
/*import {
  useStripe,
} from "@stripe/react-stripe-js";*/
import { request, ContentTypes } from '../../../../../store/api/api';
import * as JSLib from '../../../../../lib/Lib';
import { Link } from 'react-router-dom';

function Schedule() {
  const session = JS.getSession();
  const {account_email, account_firstname, account_lastname} = session.account;
  const [channel, setChannel] = useState(session.custom.channels[0]);
  const timeNowFormat = JSLib.getTime();
  const timeNow = `${timeNowFormat.substring(0,4)}-${timeNowFormat.substring(5,7)}-${timeNowFormat.substring(8,10)}`;

  //const stripe = useStripe();
  const [schedules, setSchedules ] = useState();

  useEffect(()=>{
    if(!schedules) {
      getEndpointSchedules();
    }
  })

  const getEndpointSchedules = async () => {
    const {channel_id} = channel;
    const payload = {
      channel_id: channel_id,
      schedule_date: timeNow
    };
    const endpoint = `/schedule`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      // const { data } = await axios.post("http://localhost:3020/get_payments", payload);
      const paymentsResult = res.data.data;
      const schedulesParser = paymentsResult.map((item)=> {
        const time_start = `${item.schedule_date} ${item.schedule_time_start}-${item.schedule_time_end}`;
        const fullname = (item.customer_data.customer_firstname) ? `${item.customer_data.customer_firstname} ${item.customer_data.customer_lastname}` : '';
        const contact = item.customer_email;

        const dataItem = {
          time_start,
          fullname,
          contact
        }
        return dataItem;
      });
      schedulesOrder(schedulesParser);
      setSchedules(schedulesParser);
    } catch (error) {
      console.log(error);
    }
  };

  const schedulesOrder = (schedulesParser) => {
    const scheduledOrderer = schedulesParser.sort(JSLib.dynamicSort("time_start"));
    return scheduledOrderer;
  }

  if(schedules && schedules.length===0) {
    return <div className='table-empty'>No tienes meetings pendientes durante los próximos días.</div>
  }

  return (
    <div>
      <div className='table-wrapper'>
        <div className='table-scroll'>
          <Table>
            <Thead>
              <Tr>
                <Th>Fecha de meeting</Th><Th>Nombre y apellido</Th><Th>Contacto</Th><Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {schedules ? schedules.map((item, index)=>{
                const {time_start, fullname, contact} = item;
                const link = `${JSLib.getHost()}/room?group=support&from=support&to=${contact}`
                return (
                  <Tr key={index}>
                    <Td>{JSLib.convertTime(time_start)}</Td><Td>{fullname}</Td><Td>{contact}</Td><Td><Link to='/dashboard'>Ir al meeting</Link></Td>
                  </Tr>
                )
              }) : null}
            </Tbody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default Schedule;