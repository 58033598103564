import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'grovefy-ui-pack';
import CheckoutCustomerForm from './CheckoutCustomerForm';
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe('pk_test_51NFu7RIDNKmlHtrMB6HxuRHOGbzHBMLYV0Evn0lrSAqnUHo8lPDH2bQE10DgrJmuk3bz745ERC3Ggl1oZFc8Nvmz00Meld3Yhr');

// pk_test_51NFu7RIDNKmlHtrMB6HxuRHOGbzHBMLYV0Evn0lrSAqnUHo8lPDH2bQE10DgrJmuk3bz745ERC3Ggl1oZFc8Nvmz00Meld3Yhr


function PaymentCustomer() {
  const urlParameters = window.location.search;
  const urlParams = new URLSearchParams(urlParameters);
  const dataPaymentDecoded = JSON.parse(JS.decodeString(urlParams.get('dataPayment')));

  useEffect(()=>{})

  return (
    <div className='payment-wrapper'>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <H3>Servicios Rapimeet</H3>
          </Col>
        </Row>
        {/*<Row>
          <Col>
            <Input type='text' value='' placeholder='Numero de tarjeta'/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type='text' value='' placeholder='MM/AA'/>
          </Col>
        </Row>*/}
        <Row>
          <Col>
            <Elements stripe={stripePromise}>
              <CheckoutCustomerForm payment={dataPaymentDecoded} />
            </Elements>
          </Col>
        </Row>

      </Section>
    </div>
  );
}

export default PaymentCustomer;