import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, H4, H5, Footer, Button, JS } from 'grovefy-ui-pack';
import * as JSLib from '../../../lib/Lib';
import ScheduleConfirm from './ScheduleConfirm';

import './Schedule.css';
/* eslint-disable */
declare var grovefy: any; // Declare global realtime communication
const host = JSLib.getHost(); // location.host==='localhost:8081' ? 'http://localhost:8081' : 'https://'+location.host;

function Schedule() {
  const {t} = useTranslation('common');
  const { channel_linkname } = useParams();
  const [channel, setChannel] = useState();
  const [members, setMembers] = useState();
  const [callStatus, setCallStatus] = useState('stop');
  const [daysSession, setDaysSession] = useState();
  const [scheduleBusy, setScheduleBusy] = useState([]);
  const timeNowFormat = JSLib.getTime();
  const timeNow = `${timeNowFormat.substring(0,4)}-${timeNowFormat.substring(5,7)}-${timeNowFormat.substring(8,10)}`;
  const [timeSelected, setTimeSelected] = useState(timeNow);
  const [dayNameSelected, setDayNameSelected] = useState('');
  const [daysSessionsAvailable, setDaysSessionsAvailable] = useState([]);
  const [scheduleSelected, setScheduleSelected] = useState();
  const [showReserve, setShowReserve] = useState(false);
  useEffect(() => {
    if(!channel) {
      getChannel();
    }
  });

  const getEndpointScheduleCalendar = async (channel_id) => {
    const data = {
      channel_id: channel_id
    };
    const endpoint = `/schedule_calendar`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const scheduleCalendarResult = res.data.data;
      if(scheduleCalendarResult!==undefined) {
        setDaysSession(scheduleCalendarResult.calendar_sessions);
        const schedule_data = await getEndpointSchedule(channel_id, '2023-11-01');
        setScheduleBusy(schedule_data);
        // setLoading(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getMember(channel_id) {
    const data = {
      "channel_id" : channel_id.toString()
    };
    const endpoint = `/member/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      setMembers(result);
    } catch (err) {
      console.log(err)
    }
  };

  // Get branch related to the meet
  async function getChannel() {
    const data = {
      "channel_linkname" : channel_linkname
    };
    const endpoint = `/channel`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data.data;
      // console.log('getChannel: result:', result);
      getMember(result.channel_id);
      setChannel(result);
      const scheduleCalendar = await getEndpointScheduleCalendar(result.channel_id);
     // console.log('scheduleCalendar: ', scheduleCalendar);
    } catch (err) {
      console.log(err)
    }
  };

  const getEndpointSchedule = async (channel_id, schedule_date) => {
    const data = {
      channel_id,
      schedule_date
    };
    const endpoint = `/schedule`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const scheduleResult = res.data.data;
      if(scheduleResult!==undefined) {
        return scheduleResult;
        // setLoading(true);
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const isDaySessionAvailable = (daySelected, daySessionItem) => {
    let isValid = false;
    scheduleBusy.forEach((scheduleBusyItem, index)=>{
      if(daySelected===scheduleBusyItem.schedule_date) {
        // case 1 exactly equal
        if((scheduleBusyItem.schedule_time_start===daySessionItem.time_start)&&(scheduleBusyItem.schedule_time_end===daySessionItem.time_end)) {
          isValid=true;
        }
        // case 2: example 15:00-16:00 14:30-15:10
        if((daySessionItem.time_start < scheduleBusyItem.schedule_time_end)
          &&(daySessionItem.time_end > scheduleBusyItem.schedule_time_end)) {
          isValid=true;
        }
        // case 2: example 14:00-15:00 14:30-15:10
        if((daySessionItem.time_start < scheduleBusyItem.schedule_time_start)
        &&(daySessionItem.time_end > scheduleBusyItem.schedule_time_start)) {
          isValid=true;
        }
        // case 2: example 14:00-15:00 13:30-14:22
        if((daySessionItem.time_start < scheduleBusyItem.schedule_time_end)
        &&(daySessionItem.time_end > scheduleBusyItem.schedule_time_end)) {
          isValid=true;
        }
        // case 2: example 14:00-15:00, 17:00-18:00 13:30-14:22
        if((daySessionItem.time_start < scheduleBusyItem.schedule_time_start)
        &&(daySessionItem.time_end > scheduleBusyItem.schedule_time_end)) {
          isValid=true;
        }
      }
    })
    return isValid;
  }

  const handleChangeDaySession = async (e) => {
    // console.log('e.target.value', e.target.value);
    const daySelected = e.target.value;
    setTimeSelected(daySelected);
    const day_name_selected = new Date(daySelected).toLocaleString('en-us', {weekday:'long'}).toLocaleLowerCase();
    const day_selected_sessions = daysSession.find((item)=>item.day_name===day_name_selected);
    setDayNameSelected(day_name_selected);
    let daysSessionAvailable =[];
    day_selected_sessions.day_time_sessions.forEach((daySessionItem)=>{
      if(isDaySessionAvailable(daySelected, daySessionItem)!==true) {
        const sessionAvailable = {
          "schedule_date": daySelected,
          "schedule_time_start": daySessionItem.time_start,
          "schedule_time_end":  daySessionItem.time_end
        };
        daysSessionAvailable.push(sessionAvailable) 
      }
    });
    setScheduleSelected();
    setDaysSessionsAvailable(daysSessionAvailable);
  }

  const handleChooseDaySession = (daySessionAvailableItem) => {
    setScheduleSelected(daySessionAvailableItem);
  }

  const handleSchedule = () => {
    setShowReserve(true);
  }

  const renderChooseDaySession = () => {
    return (
      <div>
        <Row>
          <Col>
            <input className='inp date' type='date' value={timeSelected} min={timeNow} onChange={(e)=>handleChangeDaySession(e)}/>
          </Col>
        </Row>
        <Row>
          <Col>
            {dayNameSelected ? <H5>{t(`days.${dayNameSelected}`)}</H5> : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='day-sessions'>
              {daysSessionsAvailable.length>0 ? (daysSessionsAvailable.map((daySessionAvailableItem, index)=> {
                return (
                  <div key={index} className={`day-session-item ${(scheduleSelected && scheduleSelected.schedule_time_start===daySessionAvailableItem.schedule_time_start) ? 'selected' : '' }`} onClick={()=>handleChooseDaySession(daySessionAvailableItem)}>                  
                    {daySessionAvailableItem.schedule_time_start} a {daySessionAvailableItem.schedule_time_end}hs
                  </div>
                )
              })): <div>No hay disponibilidad para esta fecha.</div>}
            </div>   
          </Col>
        </Row>
        <Row>
          <Col>
            {scheduleSelected ? <div><Button label='Continuar' action={()=>handleSchedule()}/></div> : null}
          </Col>
        </Row>
      </div>
    )
  }

  const renderSchedule = () => {
    const { channel_linkname, channel_name, channel_description, calendar_status } = channel;

    return (
      <div className='meet-contact'>
        <Row>
          <Col>
            <H4>Agendar reunión</H4>
          </Col>
        </Row>
        <Row>
          <Col className='align-center'>
            <div className='channel-description'>
              <div className='title'><H5>{channel_name}</H5></div>
              <div>{channel_description}</div>
              <div className='link-wrapper'>rapimeet.com/<span className='linkname'>{channel_linkname}</span></div>
            </div>
          </Col>
        </Row>
        {daysSession && daysSession.length>0 ? renderChooseDaySession() : <div>No hay disponibilidad para esta fecha.</div>}
      </div>
    )
  }

  if(showReserve===true) {
    return <ScheduleConfirm scheduleSelected={scheduleSelected} channel={channel}/>
  }

  return (
    <div className='meet-wrapper'>
      <div className='meet-header'><div className='customer-logo'></div></div>
      <div className='meet-container'>
        <Section maxWidth='small' className='box-center'>
          <div className='welcome-wrapper'>
            <div>
              <Row>
                <Col>
                  {(channel) ? 
                    renderSchedule() 
                    : <div>No hemos encontrado el calendario para "{channel_linkname}".</div>
                  }
                </Col>
              </Row>
            </div>
            <div className='meet-info'>
              <a href='https://rapimeet.com'>Rapimeet.com</a>
              <div>Monta tu negocio de sesiones online en minutos.</div>
            </div>
          </div>
        </Section>
      </div>
      <Footer
        companyName='Rapimeet, Todos los derechos reservados'
        linkPrivacy='./privacy'
        linkTerms='./terms'
      ></Footer>
    </div>
  );
}

export default Schedule;