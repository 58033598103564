import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, H3, H5,} from 'grovefy-ui-pack';
import { PREFIX_URL } from '../../../store/constants';

function Privacy() {
  const {t} = useTranslation('common');

  return (
    <div>
      <Section maxWidth='medium'>
        <Row>
          <Col><H3>Política de privacidad</H3></Col>
        </Row>
        <Row>
          <Col>
            Rapimeet se toma muy en serio la privacidad de sus usuarios. Rapimeet recopila únicamente los datos de los usuarios de plataforma Rapimeet que son necesarios para proporcionar el servicio y para garantizar que se preste adecuadamente con las distintas configuraciones de cada uno de nuestros usuarios. Estos datos incluyen información técnica básica, como la dirección IP del usuario y los detalles de su sistema operativo y su dispositivo.
          </Col>
        </Row>
        <Row>
          <Col>
            Rapimeet ha implementado medidas de seguridad para proteger la privacidad de nuestros usuarios, entre las que se incluyen controles sólidos y validados para impedir el acceso no autorizado a cualquier contenido que los usuarios compartan durante las reuniones, incluido, entre otros, el contenido de vídeo, audio y chat de esas reuniones. A no ser que el anfitrión grabe la reunión, el contenido de vídeo, audio y chat no se almacena. Además, tanto en audio como en vídeo, se notifica a los participantes cuando el anfitrión graba una reunión a través de Rapimeet y estos tienen la opción de entrar o salir de la misma. Cuando se graba la reunión, el anfitrión decide si se almacena localmente en el equipo del anfitrión o en la nube de Rapimeet. Disponemos de controles de acceso para evitar el acceso no autorizado a las grabaciones de las reuniones guardadas en nuestra nube.
          </Col>
        </Row>
        <Row>
          <Col>
            Es importante destacar que Rapimeet no extrae datos de los usuarios ni vende a nadie datos de usuarios de ningún tipo.
          </Col>
        </Row>
        <Row>
          <Col>
            <H5>Publicidad de terceros</H5>
          </Col>
        </Row>
        <Row>
          <Col>
            Al igual que otras muchas empresas tecnológicas con presencia en línea, en nuestros sitios web de marketing, como rapimeet.com, usamos proveedores de servicios de publicidad externos (como Google) para ofrecer anuncios personalizados a nuestros usuarios sobre productos que los visitantes pueden considerar interesantes. (Por ejemplo, si visita nuestro sitio web, más adelante, dependiendo de sus preferencias de cookies, puede ver un anuncio de Rapimeet que le recuerde las características que ofrece Rapimeet). Sin embargo, esto solo se aplica a la actividad del usuario en el sitio web de Rapimeet. Los datos relativos a la actividad en la plataforma de Rapimeet, incluido el contenido de vídeo, audio y chat, nunca se entregan a terceros con fines publicitarios. Si no quiere recibir anuncios segmentados sobre Rapimeet después de visitar sitio web rapimeet.com, haga clic en el vínculo “Preferencias de cookies” situado en la parte inferior de cualquier página en el sitio de Rapimeet y ajuste el control deslizante a “Cookies necesarias”.
          </Col>
        </Row>
        <Row>
          <Col>
            Protección de la privacidad de alumnos de enseñanza primaria y secundaria (K-12)
          </Col>
        </Row>
        <Row>
          <Col>
            Estamos particularmente comprometidos con proteger la privacidad de nuestros usuarios alumnos de enseñanza primaria y secundaria.
          </Col>
        </Row>
        <Row>
          <Col>
            Hemos establecido sistemas para proteger a los alumnos de estos cursos que utilizan nuestros servicios a través de sus colegios para su educación. Tenemos una política especial que describe cómo gestiona Rapimeet los datos personales de los alumnos de enseñanza primaria y secundaria que utilicen Rapimeet a través de sus colegios, que se hayan registrado en servicios Rapimeet para sus alumnos. Esta política especial está diseñada para reflejar nuestro cumplimiento de los requisitos de la Ley de protección de la privacidad infantil en Internet (COPPA) de EE. UU., la Ley de privacidad del consumidor de California (CCPA), la Ley de derechos educativos y privacidad familiar (FERPA) y otras leyes aplicables. 
          </Col>
        </Row>
        <Row>
          <Col>
            Además de proporcionar servicios a alumnos de enseñanza primaria y secundaria a través de suscriptores escolares como ya se ha indicado, Rapimeet no permite conscientemente a los niños menores de 16 años registrarse para obtener sus propias cuentas.  Si usted es un padre/madre o tutor legal y cree que su hijo nos ha proporcionado información, puede contactar con nosotros a través de support@rapimeet.com y tomaremos las medidas adecuadas para investigar y abordar el problema.
          </Col>
        </Row>
        <Row>
          <Col>
            <H5>Cumplimiento de la ley</H5>
          </Col>
        </Row>
        <Row>
          <Col>Cumplimos nuestras obligaciones legales. Esto incluye responder a los procesos jurídicos válidos, incluida la jurisdicción. También cumplimos las obligaciones legales de nuestros clientes.
          </Col>
        </Row>
        <Row>
          <Col>
            <H5>Nuestro compromiso</H5>
          </Col>
        </Row>
        <Row>
          <Col>
            Como siempre, seguiremos revisando y desarrollando nuestro enfoque de privacidad para garantizar que hacemos lo correcto para nuestros usuarios. Mantenemos nuestro compromiso de proteger la privacidad de los datos de nuestros clientes y consideramos que la privacidad es una parte fundamental de los valores de nuestra empresa: cuidar de nuestra comunidad, nuestros clientes, nuestros empleados y nuestra compañía.
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Privacy;