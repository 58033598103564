import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H3, H5, Button, JS } from 'grovefy-ui-pack';

function Installation() {
  const session = JS.getSession();

  const encodeString = (string) => {
    const encodedString = btoa(string);
    return encodedString;
  }

  return (
    <div>
      <Section>
        <Row>
          <Col>
            <H3>Compartir</H3>
          </Col>
        </Row>
        <Row>
          <Col>
            Haz que tus clientes se comuniquen fácilmente contigo, comparte pantalla y brinda soporte o vende mejor tus productos.
          </Col>
        </Row>
      </Section>
      <Section className='medium'>
        <Row>
          <Col>
            <H5>Enlace directo</H5>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Comparte tu enlace de Rapimeet en redes sociales, Whatsapp, email o vía telefónica.
          </Col>
          <Col>
            {session.custom.channels.map((item)=>{
              const rapimeet_link = `https://rapimeet.com/${item.channel_linkname}`;
              return (<div>
                <div className='bottom-20'>
                  <div className='align-right'><button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(rapimeet_link)}>Copiar</button> </div>
                  <input className='inp' value={`${rapimeet_link}`}/>
                </div>
              </div>)
            })}
          </Col>
        </Row>
      </Section>
      {/*<Section>
        <Row>
          <Col>
            <H5>Botón flotante al pie de página</H5>
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Código para incrustar en tu website, si tienes dudas haz una <a href=''>Rapimeet</a> con nosotros y te indicamos como (solo premium).
          </Col>
          <Col>
            <textarea placeholder=''>

            </textarea>
          </Col>
          <Col>
          </Col>
        </Row>
      </Section>
      */}
      <Section className='medium'>
        <Row>
          <Col>
            <H5>Botón simple</H5>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Inserta botón de Rapimeet en tu website.
          </Col>
          <Col>
            {session.custom.channels.map((item)=>{
              const textHtml = "<a href='https://rapimeet.com/"+item.channel_linkname+"' target='_blank'><img src='https://rapimeet.com/statics/img/btn-rapimeet.svg'></img></a>";
              return (
                <div>
                  <a href={`https://rapimeet.com/${item.channel_linkname}`} target='_blank'>
                    <img src='https://rapimeet.com/statics/img/btn-rapimeet.svg'></img>
                  </a>
                  <div>
                    <div className='align-right'><button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(textHtml)}>Copiar</button> </div>
                    <textarea disabled placeholder='' value={`${textHtml}`} className='textarea-height'>
                    </textarea>
                  </div>
                </div>
              )
            })}
          </Col>
        </Row>
      </Section>
      <Section className='medium'>
        <Row>
          <Col>
            <H5>Botón flotante al pie de página</H5>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Inserta botón de Rapimeet en tu website.
          </Col>
          <Col>
            {session.custom.channels.map((item)=> {
              const rmToken = encodeString(JSON.stringify({linkname: item.channel_linkname}));
              const textHtml = `<script src='https://rapimeet.com/statics/v3/rapimeet.js'></script><script>const rmToken = '${rmToken}'; rapimeet.init(rmToken);</script>`;
              return (
                <div>
                  <a href={`https://rapimeet.com/${item.channel_linkname}`} target='_blank'>
                    <img src='https://rapimeet.com/statics/img/btn-rapimeet.svg'></img>
                  </a>
                  <div>
                    <div className='align-right'><button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(textHtml)}>Copiar</button> </div>
                    <textarea disabled placeholder='' value={`${textHtml}`} className='textarea-height'>
                    </textarea>
                  </div>
                </div>
              )
            })}
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Installation;