import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'grovefy-ui-pack';
import { PREFIX_URL } from '../../../store/constants';
import PricesList from '../../../components/pricesList/PricesList';

function Prices() {
  const {t} = useTranslation('common');

  return (
    <div className='medium'>
      <Section>
        <Row>
          <Col className='align-center'><H3>Precios</H3></Col>
        </Row>
        <Row>
          <Col className='align-center'>
            <div className='wrapper-medium'>
              <H3 className='subtitle'>Monta tu negocio de sesiones online en minutos y comienza a generar nuevos ingresos</H3>
            </div>
          </Col>
        </Row>
        <PricesList />
      </Section>
    </div>
  );
}

export default Prices;