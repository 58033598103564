import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setItems } from '../../../store/items/ItemsActions';
//import { getStatus } from '../../../store/api/api';
import { request, ContentTypes } from '../../../store/api/api';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, H1, H2, H3, H4, H5, Input, Button, Box, JS } from "grovefy-ui-pack";
import './Home.css';
import PricesList from '../../../components/pricesList/PricesList';

function Home() {
  const { promotional_code_code } = useParams();
  JS.setCookie({cname:'promotional_code', cvalue: promotional_code_code});
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const {t, i18n} = useTranslation('common');
  const { items } = useSelector((state: any) => state.default.items)
  const dispatch = useDispatch();

  useEffect(() => {
/*    if(!items.id) {
      dispatch(setItems({ id:1, title: 'Example item title'}));
      getStatus();
    }*/
  });
/*
  async function getStatus() {
    const data = {
      "email":"cjgaraycochea@gmail.com"
    };
    const endpoint = `/get-item`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log(res);
      if (result) {
        console.log(result);
      }
    } catch (err) {
      console.log(err)
    }
    // dispatch(signUp(data));
  };
*/

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return (
    <div className='home'>
      <div className='hero-wrapper'>
        <div className='hero-content'>
          <Row>
            <Col><H1 className='title'>Monta tu negocio de sesiones online en minutos</H1></Col>
          </Row>
          <Row>
            <Col>
              <H4 className='subtitle'>
                Atiende a tus clientes a través de meetings y recibe pagos al instante. Ideal ofrecer servicios profesionales, clases virtuales y soporte.
                {/*Comparte tu enlace Rapimeet y atiende al instante a tus clientes a través de chat, 
                llamada de voz, videollamada, pantalla compartida y acepta pagos instántaneos.*/}
              </H4>
            </Col>
          </Row>
          <Row>
            <Col className='bottom'>
              <a href='./signUp'>
                <Button className='large primary' label='¡Comenzar!'></Button>
              </a>
            </Col>
          </Row>
        </div>
        <div className='hero-image'>
        </div>
      </div>
      <Section maxWidth='large'>
        <Row>
          <Col className='align-center'>
            <div className='max-width-medium'>
              <H2>Bienvenido</H2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='align-center'>
            <div className='max-width-medium'>
              <H3 className='subtitle'>Rapimeet permite montar tu sistema de sesiones online, agendar meetings y recibir pagos instántaneos</H3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='align-center'>
            <div className='max-width-medium'>
              Ofrece a tus clientes servicios de asesoramiento a través de reuniones virtuales, ideal para abogados, psicólogos, nutricionistas, apoyo escolar, clases de inglés, programación, fitness, yoga, soporte, ventas y más.
            </div>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col>
            <div className='description-image'>
            </div>
          </Col>
          <Col>
            <div className='description-items'>
              <div className='item'>
                <div className='image'><div className='check-image'></div></div>
                <div className='content'>
                  <div className='title'>Llega a <b>más clientes</b> en cualquier parte del mundo.</div>
                </div>
              </div>
              <div className='item'>
                <div className='image'><div className='check-image'></div></div>
                <div className='content'>
                  <div className='title'><b>Comparte tu enlace</b> y recibe llamadas de voz, videollamadas o chat.</div>
                </div>
              </div>
              <div className='item'>
                <div className='image'><div className='check-image'></div></div>
                <div className='content'>
                  <div className='title'><b>Recibe pagos</b> instantáneos con Bizum, cuenta bancaria, tarjeta u otras formas de pago.</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        
      </Section>
      <Section maxWidth='large'>
        <Row>
          <Col className='align-center'>
            <div className='max-width-medium'>
              <H2>Comienza en tres simples pasos</H2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='steps-items'>
              <div className='item'>
                <div className='step-circle'>1</div>
                <div className='title'><H4>Crea tu Rapimeet</H4></div>
                <div className='image'><div className='step-item-create'></div></div>
                <div className='content'>
                  <div className='description'>Crea tu usuario y define tu disponibilidad horaria.</div>
                </div>
              </div>
              <div className='item'>
                <div className='step-circle'>2</div>
                <div className='title'><H4>Ofrece servicios online</H4></div>
                <div className='image'><div className='step-item-share'></div></div>
                <div className='content'>
                  <div className='description'>Comparte tu enlace Rapimeet en tus redes sociales, web y promuevelo donde quieras.</div>
                </div>
              </div>
              <div className='item'>
                <div className='step-circle'>3</div>
                <div className='title'><H4>Recibe pagos</H4></div>
                <div className='image'><div className='step-item-payment'></div></div>
                <div className='content'>
                  <div className='description'>Recibe tus pagos a través de Bizum, Cuenta Bancaria o Tarjeta. O incluye tu sistema de cobro habitual.</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
      <Section maxWidth='large'>
        <Row>
          <Col className='align-center'>
            <div className='max-width-medium'>
              <H2>Registrate ahora</H2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='align-center'>
            <div className='max-width-medium'>
              <H3 className='subtitle'>Monta tu negocio de sesiones online en minutos y comienza a generar nuevos ingresos</H3>
            </div>
          </Col>
        </Row>
        <PricesList />
      </Section>
      <div className='float-bottom'>
        <a href='https://rapimeet.com/support' target='_blank'><img src='https://rapimeet.com/statics/img/btn-rapimeet.svg'></img></a>
      </div>
    </div>
  );
}

export default Home;