import React, {useState} from 'react';
import { Row, Col, Notification, Input, Button } from 'grovefy-ui-pack';
import './PaymentGetPremiumModal.css'

const PaymentGetPremiumModal = (props: any) => {
  const { close, save } = props;
  const [formData, setFormData] = useState({
    amount: '',
  });

  const saveModal = () => {
    window.location.href ='https://rapimeet.com';
  }

  const closeModal = () => {
    close();
  }

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return (
    <div className='w-100'>
      <div className='modal-header'>Recibir pagos</div>
      <div className='modal-content'>
        <Row>
          <Col>
            <Notification className='alert'>Para recibir pagos a través de Rapimeet debes crear tu cuenta Premium.</Notification>
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Registrarme'/>
      </div>
    </div>
  )
}

export default PaymentGetPremiumModal;
