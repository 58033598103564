import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { setItems } from '../../../store/items/ItemsActions';
//import { getStatus } from '../../../store/api/api';
import { requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'grovefy-ui-pack';

function ChangePassword(props) {
  const { email_to_encode } = props.match.params;
  const { t } = useTranslation('common');
  // const { items } = useSelector((state: any) => state.default.items)
  const [formData, setFormData] = useState({
    password: '',
    repeat_password: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [recovered, setRecovered] = useState(false);

  useEffect(() => {

  });

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function recoveryPasswordAccount(payload) {
    const data = {
      "email": payload.email,
      "password": payload.password
    };
    const endpoint = `/recovery-password-account`;
    try {
      const response = await requestSwitcherAPI('ACCOUNT','POST', endpoint, data, { 'content-type': ContentTypes.json });
      if(response.data) {
        setRecovered(true);
      }

    } catch (err) {
      console.log(err)
    }
  };


  async function handleSubmitRecoveryPassword(event: any) {
    // event.preventDefault();
    const email_to = JS.decodeString(email_to_encode)
    const data = {
      "email_login": email_to.toLowerCase()
    };

    const endpoint = `/exist-email`;
    try {
      const res = await requestSwitcherAPI('ACCOUNT','POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;
      if (user && user.id) {
        const { email } = user;
        const payload = {
          email: email,
          password: formData.password
        }
        recoveryPasswordAccount(payload);
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Email no existe!'
        });
      }
    } catch (err) {
      console.log(err)
    }
  };

  if(recovered) {
    return (
      <div>
        <Section maxWidth='small'>
          <Row>
            <Col><H3>Recuperar acceso</H3></Col>
          </Row>
          <Row>
            <Col>
              <Notification className='success'>¡Felicidades! Tu contraseña se ha recuperado correctamente, puedes iniciar sesión.</Notification>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button label='Ir a login' className='primary' action={()=>window.location.href='/'}/>
            </Col>
          </Row>
        </Section>
      </div>
    );    
  }

  return (
    <div>
      <Section maxWidth='small' className='box-center'>
        <Row>
          <Col><H3>Recuperar acceso</H3></Col>
        </Row>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>Indica el correo que usas para iniciar sesión en Rapimeet y te enviaremos un enlace para restablecer tu contraseña.</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <Input type='password' name='password' value={formData.password} placeholder={t('sign_up.password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='password' name='repeat_password' value={formData.repeat_password} placeholder={t('sign_up.repeat_password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button label={t('forgot_password.send')} className='primary' action={e => handleSubmitRecoveryPassword(e)}/>
            </Col>
          </Row>

        <Row>
          <Col>
            {t('forgot_password.do_you_have_account')} <Link to='/login'>{t('forgot_password.login')}</Link>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default ChangePassword;