import React from 'react';
import { Section, Row, Col, Loading } from 'grovefy-ui-pack';
import './Sessions.css';
import { PREFIX_URL } from '../../../store/constants';

function Sessions() {
  const iframe_preload_payment = `<iframe src='https://${PREFIX_URL}payment.grovefy.com/session/index.html' />`;
  const iframe_preload_play = `<iframe src='https://${PREFIX_URL}play.grovefy.com/session/index.html' />`;

  setTimeout(function () {
    window.location.href = `https://${PREFIX_URL}payment.grovefy.com`;
  }, 3000);

  return (
    <div>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <Loading visible={true} className='center'/>
          </Col>
        </Row>
      </Section>
      <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_payment+" "}} />
      <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_play+" "}} />
    </div>
  );
}

export default Sessions;