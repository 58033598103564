import React from 'react';
import { Header, Container, Footer } from 'grovefy-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';

// const session_token = 'rm-eyJhcHBsaWNhdGlvbiI6ImV4YW1wbGUiLCJhY2NvdW50Ijp7ImFjY291bnRfaWQiOjMsImFjY291bnRfZW1haWwiOiJ0ZXN0NEBnbWFpbC5jb20iLCJhY2NvdW50X3Bhc3N3b3JkIjoiMzIxRGVtbyQiLCJhY2NvdW50X2ZpcnN0bmFtZSI6IlRlc3QgNCIsImFjY291bnRfbGFzdG5hbWUiOiJUZXN0IDQiLCJhY2NvdW50X3JvbGUiOiJjdXN0b21lciIsImNoYW5uZWxzIjpbXX0sInRva2VucyI6eyJhcGlfcGF5bWVudCI6IiIsImFwaV9hY2NvdW50IjoiIn0sInBheW1lbnRzIjp7InN0YXR1cyI6ImFjdGl2ZSJ9fQ==';
// JS.setCookie({cname:'session_token', cvalue: session_token});

function PrivateLayout(props: any) {
  
  const menu = () => {
    return (
      <ul>
        <li><a href='/'>Home</a></li>
        <li><a href='/prices'>Precios</a></li>
        <li><a href='/jobs'>Empleo</a></li>
      </ul>
    )
  }

  return (
    <div className="public-layout bg-public">
      <Header sessionLinks={sessionLinks}>{menu()}</Header>
      <Container>
        {props.children}
      </Container>
      <Footer
        companyName='Rapimeet, Todos los derechos reservados'
        linkPrivacy='./privacy'
        linkTerms='./terms'
      >{menu()}</Footer>
    </div>
  );
}

export default PrivateLayout