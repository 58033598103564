import React from 'react';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, H3 } from 'grovefy-ui-pack';

function DevOps() {
  const {t} = useTranslation('common');

  return (
    <div className='medium'>
      <Section maxWidth='medium'>
        <Row>
          <Col><H3>DevOps Engineer</H3></Col>
        </Row>
        <Row>
          <Col>New York, New York, US</Col>
        </Row>
        <Row>
          <Col>
            We are a digitally native technology services company where innovation, design and engineering meet scale. We use some of the latest technologies in the digital and cognitive field to empower organizations in every aspect.
          </Col>
        </Row>
        <Row>
          <Col>
            Right now, we are looking for DevOps Engineers to join to Rapimeet!
            This Studio combines the best cloud technologies, continuous integration and continuous delivery practices along with unique Rapimeet capabilities to facilitate new and more efficient way of doing business.
          </Col>
        </Row>
        <Row>
          <Col>
            <div>You will get the chance to:</div>
            <ul style={{marginLeft: 40}}>
              <li>Work with professionals who have created some of the most revolutionary solutions in their fields.</li>
              <li>Make an impact. Work in large-scale projects globally.</li>
              <li>Develop your career in our Studios. Each Studio represents deep pockets of expertise on the latest technologies and trends and delivers tailored solutions focused on specific challenges.</li>
              <li>Develop your career within an industry or multiple industries.</li>
              <li>Work in the city you want, and be nourished by cultural exchanges.</li>
              <li>Be empowered to choose your career path: we have more than 600 simultaneous projects, so you can choose where and how to work.</li>
              <li>Be part of an agile pod. Driven by a culture of self-regulated teamwork, each team -or POD- works directly with our customers with a full maturity path that evolves as they increase speed, quality and autonomy.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>What will help you succeed:</div>
            <ul style={{marginLeft: 40}}>
              <li>Believe and love what you do.</li>
              <li>Build bridges & open doors.</li>
              <li>Enjoy solving problems.</li>
              <li>Being capable of taking a step back to look at a bigger picture.</li>
              <li>Intermediate or upper English skills.</li>
              <li>Solid Linux/Unix administration skills.</li>
              <li>Strong Puppet or Chef experience (either for Configuration Management and/or Continuous Delivery) and integration with virtual environments (Vagrant, VirtualBox, AWS EC2).</li>
              <li>A development background and a strong scripting knowledge.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            At Rapimeet we believe that an inclusive culture and a diverse environment makes us stronger. We encourage people to have an inclusive spirit as our global footprint expands. We seek to generate a place of inspiration and growth for everyone. A safe space, based on equity as a value, where everyone's careers can be promoted and developed in the same way. There is no innovation without diversity and there is no improvement without plurality. 
          </Col>
        </Row>
        <Row>
          <Col>
            <a href='to: jobs@rapimeet.com'>jobs@rapimeet.com</a>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default DevOps;