/* Pages */
// import ErrorPage from '../containers/errorPage/ErrorPage';
import IRoute from '../models/route';

// Home
import Home from '../pages/public/home/Home';
// import Building from '../pages/public/building/Building';

import Welcome from '../pages/public/welcome/Welcome';
import Client from '../pages/public/client/Client';
import Admin from '../pages/private/admin/Admin';
import Room from '../pages/public/room/Room';

import Login from '../pages/public/login/Login';
import SignUp from '../pages/public/signUp/SignUp';
import ForgotPassword from '../pages/public/forgotPassword/ForgotPassword';
import ChangePassword from '../pages/public/forgotPassword/ChangePassword';
import Sessions from '../pages/public/sessions/Sessions';
import Close from '../pages/public/close/Close';
import Profile from '../pages/private/profile/Profile';
import Statistics from '../pages/private/statistics/Statistics';
import Monetize from '../pages/private/monetize/Monetize';
import Installation from '../pages/private/installation/Installation';
import CreateChannel from '../pages/private/createChannel/CreateChannel';
import Payment from '../pages/public/payment/Payment';
import PaymentCustomer from '../pages/public/paymentCustomer/PaymentCustomer';
import PaymentGen from '../pages/public/paymentCustomer/paymentGen/PaymentGen';

// Private
// import Search from '../pages/private/search/Search';
import Terms from '../pages/public/terms/Terms';
import Privacy from '../pages/public/privacy/Privacy';

import Prices from '../pages/public/prices/Prices';
import Jobs from '../pages/public/jobs/Jobs';
import DevOps from '../pages/public/jobs/posts/DevOps29231';
import Analyst from '../pages/public/jobs/posts/Analyst37516';

/* Layouts */
import PublicLayout from '../layouts/publicLayout/PublicLayout';
import DashboardLayout from '../layouts/dashboardLayout/DashboardLayout';
import PrivateLayout from '../layouts/privateLayout/PrivateLayout';
import FullLayout from '../layouts/fullLayout/FullLayout';
import Calendar from '../pages/private/calendar/Calendar';
import Schedule from '../pages/public/schedule/Schedule';
import Recall from '../pages/public/recall/Recall';
import PaymentTier from '../pages/public/paymentTier/PaymentTier';
import PromotionalCode from '../pages/public/site/promotionalCode/PromotionalCode';
import PromotionalCodeAdmin from '../pages/public/site/promotionalCode/PromotionalCodeAdmin';

// import Search from '../pages/private/search/Search';

export const routes: IRoute[] = [
  {
    path: '/privacy',
    name: 'Privacy Page',
    component: Privacy,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/terms',
    name: 'Terms Page',
    component: Terms,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/prices',
    name: 'Prices Page',
    component: Prices,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/jobs/analyst/37516',
    name: 'Analyst Page',
    component: Analyst,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/jobs/devops/29231',
    name: 'Dev Ops Page',
    component: DevOps,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/jobs',
    name: 'Jobs Page',
    component: Jobs,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/dashboard/calendar',
    name: 'Calendar Page',
    component: Calendar,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/paymentGen',
    name: 'Payment Gen Page',
    component: PaymentGen,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/paymentCustomer',
    name: 'Payment Customer Page',
    component: PaymentCustomer,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/paymentTier',
    name: 'Payment Tier Page',
    component: PaymentTier,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/payment',
    name: 'Payment Page',
    component: Payment,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/dashboard/monetize',
    name: 'Monetize Page',
    component: Monetize,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/statistics',
    name: 'Statistics Page',
    component: Statistics,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/installation',
    name: 'Installation Page',
    component: Installation,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/profile',
    name: 'Profile Page',
    component: Profile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/',
    name: 'Admin Page',
    component: Admin,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/room',
    name: 'Room Page',
    component: Room,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/client',
    name: 'Client Page',
    component: Client,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/create-channel',
    name: 'Create channel',
    component: CreateChannel,
    layout: PrivateLayout,
    exact: true
  },
  {
    path: '/close',
    name: 'Close Page',
    component: Close,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/changePassword',
    name: 'ChangePassword Page',
    component: ChangePassword,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/sessions',
    name: 'Sessions Page',
    component: Sessions,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/changePassword',
    name: 'ChangePassword Page',
    component: ChangePassword,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword Page',
    component: ForgotPassword,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/signUp/:account_tier',
    name: 'Sign Up Page',
    component: SignUp,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/signUp',
    name: 'Sign Up Page',
    component: SignUp,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/login',
    name: 'Login Page',
    component: Login,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/schedule/:channel_linkname',
    name: 'Schedule Page',
    component: Schedule,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/recall/:channel_linkname/:from_predefined',
    name: 'Recall Page',
    component: Recall,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/:channel_linkname',
    name: 'Welcome Page',
    component: Welcome,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/promotional/admin/:promotional_code_code',
    name: 'Promotional Admin',
    component: PromotionalCodeAdmin,
    layout: PrivateLayout,
    exact: true
  },
  {
    path: '/promotional/:promotional_code_code',
    name: 'Promotional',
    component: PromotionalCode,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/code/:promotional_code_code',
    name: 'Welcome Page',
    component: Home,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/',
    name: 'Home Page',
    component: Home,
    layout: PublicLayout,
    exact: true
  }
];