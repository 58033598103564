import React, { useEffect, useState } from 'react';
import SideMenu from '../../components/sideMenu/SideMenu';
import './DashboardLayout.css';
import { getTime } from '../../lib/Lib';
import { JS } from 'grovefy-ui-pack';
import { request, ContentTypes } from '../../store/api/api';

function DashboardLayout(props: any) {
  const session = JS.getSession();
  const [channel, setChannel] = useState(session ? session.custom.channels[0] : null);
  const [channelExistStatus, setChannelExistStatus] = useState('verify');
  const [channelPaymentStatus, setChannelPaymentStatus] = useState('verify');

  useEffect(()=> {
    if(channelExistStatus==='verify') {
      setChannelExistStatus(getChannelExistStatus);
    }
    if(channelExistStatus==='exist' && channelPaymentStatus==='verify'){
      getEndpointPaymentStatus();
    }
  })

  const getChannelExistStatus = () => {
    const exist = channel && channel.channel_linkname ? true : false ;
    if(exist) {
      return 'exist'
    }
    return 'no_exist'
  }

  const getEndpointPaymentStatus = async () => {
    const data = {
      "channel_id": channel ? channel.channel_id : null,
      "limit": "1"
    }

    const endpoint = `/payment/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const paymentResponse = res.data.data;
      // if length === 0 then no exist payment
      //    setChannelPaymentStatus('unavailable')
      // if length > 0 then exist payment 
      //    if check if finish_at > getToday()UnixTimestamp
      //       setChannelPaymentStatus('available')
      //    else setChannelPaymentStatus('unavailable')

      if(paymentResponse.length===0) {
        setChannelPaymentStatus('unavailable');
      } else if(paymentResponse.length>0) {
        const todaySlash = getTime().substring(0, 11);
        const today = todaySlash.replaceAll('/','-');
        const finish_at = paymentResponse[0].finish_at.substring(0, 11);
        if(today>finish_at) {
          setChannelPaymentStatus('unavailable');
        } else {
          setChannelPaymentStatus('available');
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  if(!session.account.account_id){
    window.location.href = `/`;
  }

  if(channelExistStatus==='no_exist') {
    window.location.href = `/create-channel`;
  }

  if(channelExistStatus==='exist' && channelPaymentStatus==='unavailable') {
    window.location.href = `/paymentTier`;
  }

  if(channelExistStatus==='exist') {
    return (
      <div className="dashboard-layout">
        <SideMenu />
        <div className='main-wrapper'>
          <div className='container-fluid'>
            {props.children}
          </div>
        </div>
      </div>
    );    
  }

  return (<div></div>)
}

export default DashboardLayout