import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H3, H5, Button, Input, JS} from 'grovefy-ui-pack';
import {tierPrices, amountToStripeFormat, stripeAmountToDecimal } from '../../../lib/Lib';

function Profile() {
  const session = JS.getSession();
  const {account_email, account_firstname, account_lastname, account_tier} = session.account;
  const {channels} = session.custom;
  const prices = tierPrices();
  const tier_selected = prices.find(item => item.tier_id === account_tier);
  const { tier_name, tier_price } = tier_selected;
  const price = stripeAmountToDecimal(tier_price);

  useEffect(()=>{
    console.log('tier_selected: ',tier_selected);
  })

  return (
    <div>
      <Section>
        <H3>Perfil</H3>
      </Section>
      <Section className='medium'>
        <Row className='mb-column'>
          <Col className='label'>
            Email
          </Col>
          <Col>
            <div className='text-bold'>{account_email}</div>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Nombre completo
          </Col>
          <Col>
            <div className='text-bold'>{`${account_firstname} ${account_lastname}`}</div>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Enlace Rapimeet
          </Col>
          <Col>
            {channels.map((channelItem)=> {
              return <a href={`https://rapimeet.com/${channelItem.channel_linkname}`}>{`https://rapimeet.com/${channelItem.channel_linkname}`}</a>
            })}
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Plan
          </Col>
          <Col>
            <div className='text-bold'>{tier_name} €{price}</div>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Profile;