import React, { useEffect, useRef, useState } from "react"
import Peer from "simple-peer"
import io from "socket.io-client"
import "./VoiceCall.css";
import callMP3 from './call.mp3';
import { Modal, JS } from 'grovefy-ui-pack';
import PaymentModal from "../paymentModal/PaymentModal";
import PaymentGetPremiumModal from "../paymentGetPremiumModal/PaymentGetPremiumModal";

/* eslint-disable */
// declare var grovefy: any;

const getRoomID = (group, to, from) => {
  let customer_id = '';
  if(from.substring(0,9)==='customer_') {
    customer_id = from;
  } else {
    customer_id = to;
  } 
  return `${group}${customer_id}`;
}

function VoiceCall(props) {
  const session = JS.getSession();
  const { propsVoiceCall, config } = props;
  const { group, from, to } = config;
  const room_id = getRoomID(group, to, from);
  const [eventListener, setEventListener] = useState(false);
  /* 
    callIn: define status of the call
  */
  const [componentModal, setComponentModal] = useState(null);
  const [callStatus, setCallStatus] = useState({
    /* 
      callIn: 
      Define call status.
      call_set_up: Initial connection is established between the two phone devices.
      ringing: Recipient's phone begins to ring.
      calling: Call other device.
      answer: When the recipient answers the call.
      conversation: Where both parties can speak and listen
      call_end: When one of the parties decides to end the call.
      disconnect: The connection between the two devices is completely closed.
    */
    callIn: '',
    members: [
      {
        id: from,
        name: from,
        video: false,
        audio: true,
        shareScreen: false
      }
    ],
  });

  useEffect(()=> {
    /*
    console.log('room_id: ',room_id);
    console.log('substring', from.substring(0,9));
    console.log('group: ', group);
    console.log('from: ', from);
    console.log('to: ', to);
    
    */
    // Listener from iframe for execute function switcher "switcherEvent" {func: 'namefunction', params: {x:1, y:1}}
    if(eventListener===false) {
      /*window.addEventListener("message", (event) => {
        const eventDataString = JSON.stringify(event.data);
        if(eventDataString.substring(1,5)==='pre-') {
          const eventData = eventDataString.substring(5, eventDataString.length-1);
          const received = JSON.parse(decodeString(eventData));
           switcherEvent(received);
        }
      }, false);*/
      setEventListener(true);

      // Set callStatus with callStatus.members[0] user local as "from" 
      /*const member = {
        id: from,
        name: from,
        video: false,
        audio: false,
        shareScreen: false
      };
      const members = callStatus.members;
      members.push(member);
      setCallStatus(prevState => ({
        ...prevState,
        members
      }));*/

      // If suppor initialize room
      if(from==='support') {
        setTimeout(() => {
          document.getElementById('framevideocall').contentWindow.createRoom(room_id);
        }, 1000);
      }
    }
  })

  // Props recevied from Room.jsx > Geting remote function trough Grovefy  
  useEffect(()=> {
    if(propsVoiceCall) {
      propsVoiceCallSwitcher(propsVoiceCall);
    }    
  }, [propsVoiceCall]);

  useEffect(()=>{
    const { callIn, members } = callStatus;
    if((from!=='support') && (callIn==='answered')) {
      document.getElementById('framevideocall').contentWindow.joinRoom(room_id);
      handleCallIn({callIn: 'conversation'});
    }
    if((from!=='support') && (callIn==='answer')) {
      document.getElementById('framevideocall').contentWindow.joinRoom(room_id);
      handleCallIn({callIn: 'conversation'});
    }
    if((callIn==='answered')) {
      // document.getElementById('framevideocall').contentWindow.joinRoom(room_id);
    }
    if((callIn==='call_end')) {
      document.getElementById('framevideocall').contentWindow.stopRoom();
    }

  }, [callStatus]);

  function decodeString(encodedString) {
    if(encodedString==='') {
      return {}
    }
    var decodedString = decodeURI(atob(encodedString));
    return decodedString;
  }

  function callInReceived(parameters){
    setCallStatus(prevState => ({
      ...prevState,
      callIn: parameters.callIn
    }));
  }

  function propsVoiceCallSwitcher (propsVoiceCall) {
    const received = propsVoiceCall.data;
    switch (received.function) {
      case 'callInReceived':
        callInReceived(received.parameters);
        break;
      default:
        break;
    }
  }

  // Execute function received from iframe
  /*
  function switcherEvent (received) {
    const {func, params} = received;  
    switch (func) {
      case 'pause':
        pause(params);
        break;
      default:
        break;
    }
  }*/

  /* handleCallIn control the status of call on LOCAL and REMOTE devices. */
  const handleCallIn = (data) => {
    const { callIn } = data;
    // Set LOCAL status callStatus.callIn='calling'
    setCallStatus(prevState => ({
      ...prevState,
      callIn: callIn
    }));

    let callInRemote = '';

    if(callIn==='calling') {
      callInRemote = 'ringing';
    }
    
    if(callIn==='answer') {
      callInRemote = 'answered';
    }

    if(callIn==='conversation') {
      callInRemote = 'conversation';
    }

    if(callIn==='call_end') {
      callInRemote = 'call_end';
    }

    // Set REMOTE status callStatus.callIn='calling'
    const remoteData = {
      function: 'callInReceived',
      parameters: {
        callIn: callInRemote,
        refresh: Math.random()
      }
    };
    grovefy.send(remoteData);
  }

  /* handleMembers control the members (video true, audio muted, share screen, etc) of call on LOCAL and REMOTE devices. */
  const handleVideo = (data) => {
    const { video } = data;
    let members = callStatus.members;
    const index = callStatus.members.findIndex((member) => member.id===from);
    // Update
    members[index].video = video;

    if(video===true) {
      document.getElementById('framevideocall').contentWindow.videoEnabled();
    }
    if(video===false) {
      document.getElementById('framevideocall').contentWindow.videoDisabled();
    }

    // Set LOCAL status callStatus.callIn='calling'
    setCallStatus(prevState => ({
      ...prevState,
      members: members
    }));
  }

  /* handleMembers control the members (video true, audio muted, share screen, etc) of call on LOCAL and REMOTE devices. */
  const handleAudio = (data) => {
    const { audio } = data;
    let members = callStatus.members;
    const index = callStatus.members.findIndex((member) => member.id===from);
    // Update
    members[index].audio = audio;

    if(audio===true) {
      document.getElementById('framevideocall').contentWindow.audioEnabled();
    }
    if(audio===false) {
      document.getElementById('framevideocall').contentWindow.audioDisabled();
    }

    // Set LOCAL status callStatus.callIn='calling'
    setCallStatus(prevState => ({
      ...prevState,
      members: members
    }));
  }

  const handleShareScreen = (data) => {
    const { shareScreen } = data;
    let members = callStatus.members;
    const index = callStatus.members.findIndex((member) => member.id===from);
    // Update
    members[index].shareScreen = shareScreen;

    if(shareScreen===true) {
      document.getElementById('framevideocall').contentWindow.startShareScreen();
    }
    if(shareScreen===false) {
      document.getElementById('framevideocall').contentWindow.stopShareScreen();
    }

    // Set LOCAL status callStatus.callIn='calling'
    setCallStatus(prevState => ({
      ...prevState,
      members: members
    }));
  }

  const buttonAcceptCall = () => {
    const { callIn } = callStatus;
    if(callIn === 'ringing') {
      return <button className={`btn-circle calling`} onClick={()=>handleCallIn({callIn: 'answer'})}><div className='icon-call-accept'></div></button>
    }
    if(callIn === 'calling') {
      return <button className={`btn-circle calling`} onClick={()=>null}><div className='icon-call-accept'></div></button>
    }
    /*if (callIn === 'answer') {
      return  <button className={`btn-circle selected`} onClick={()=>null}><div className='icon-call-accept'></div></button>
    }
    if (callIn === 'answered') {
      return  <button className={`btn-circle selected`} onClick={()=>null}><div className='icon-call-accept'></div></button>
    }*/
    if (callIn === 'conversation') {
      return  <button className={`btn-circle selected`} onClick={()=>null}><div className='icon-call-accept'></div></button>
    }
    return <button className={`btn-circle accept`} onClick={()=>handleCallIn({callIn: 'calling'})}><div className='icon-call-accept'></div></button>
  }

  const memberLocal = () => {
    const member = callStatus.members.find((memberItem)=>memberItem.id===from);
    return member
  }

  function openPaymentModal() {
    const { account_id } = session.account;
    if (account_id) {
      setComponentModal(<PaymentModal close={()=>closeModal()} save={(data)=>saveModal(data)} />);
    } else {
      setComponentModal(<PaymentGetPremiumModal close={()=>closeModal()} save={()=>null} />);
    }
  }

  function saveModal(dataModal) {
    const { account_email, account_firstname, account_lastname } = session.account;
    const { channel_linkname, channel_id } = session.account.channels[0];
    const { amount } = dataModal;

    const data = {
      function: 'paymentCustomer',
      parameters: {
        payment_channel_id: channel_id,
        payment_to_channel: channel_linkname,
        payment_to_email: account_email,
        payment_to_fullname: `${account_firstname} ${account_lastname}`,
        amount: amount,
        currency: 'EUR'
      }
    };
    grovefy.send(data);
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  return (
    <div className="room">
      <div className="container">
        <iframe
          id='framevideocall'
          title="video"
          src={'../statics/videoCall/index.html?config=5555'}
          allowFullScreen
          allow="camera *;microphone *"
          style={{
            border: '0 solid',
            width: '100%',
            height: '100%'
          }}
        ></iframe>      
        {/*
        <div className="container">
          <div className="video-container">
          </div>
        </div>
        */}
        <div className="bottom">
          <div className="btn-group align-center">
            {/* btn call */}
            {buttonAcceptCall()}
            {/* btn call_end */}
            <button
              className={`btn-circle cancel`}
              onClick={()=>handleCallIn({callIn: 'call_end'})}
            >
              <div className='icon-call-accept'></div>
            </button>
            {/* btn shareScreen */}
            <button
              className={`btn-circle mb-hide ${!memberLocal().shareScreen ? 'active' : null}`}
              onClick={()=>handleShareScreen({shareScreen: !memberLocal().shareScreen})}
            >
              <div className='icon-share-screen'></div>
            </button>
            {/* btn audio */}
            <button 
              className={`btn-circle ${memberLocal().audio===true ? 'active' : 'inactive'}`} 
              onClick={()=>handleAudio({audio: !memberLocal().audio})}
            >
              {!memberLocal().audio ? <div className='icon-mic-call-inactive'></div> : <div className='icon-mic-call'></div>}
            </button>
            {/* btn video */}
            <button 
              className={`btn-circle ${memberLocal().video===true ? 'active' : 'inactive'}`}
              onClick={()=>handleVideo({video: memberLocal().video===true ? false : true})}
            >
              {memberLocal().video===true ? <div className='icon-video-call'></div> : <div className='icon-video-call-inactive'></div>}
            </button>
            {/* btn payment */}
            <button
              className={`btn-circle primary`}
              onClick={()=>openPaymentModal()}
            >
              <div className='icon-payment'></div>
            </button>
          </div>
        </div>
      </div>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  )
}

export default VoiceCall
