import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H3, Table, Thead, Tr, Th, Tbody, Td, Notification } from 'grovefy-ui-pack';

function Statistics() {
  return (
    <div>
      <Section>
        <Row>
          <Col>
            <H3>Estadísticas</H3>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col>
            <Notification className='success'>Has respondido un total de 30 meets este mes.</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <Thead>
              <Tr>
                <Th>Ultimas Meet</Th><Th>Duración</Th>
              </Tr>
              </Thead>
              <Tbody>
                <Tr key='1'>
                  <Td>10/02/2023 15:30hs</Td><Td>10 Min</Td>
                </Tr>
              </Tbody>
            </Table>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Statistics;