import React, { useEffect, useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'grovefy-ui-pack';
import {tierPrices, amountToStripeFormat, stripeAmountToDecimal } from '../../../lib/Lib';
import { request, ContentTypes } from '../../../store/api/api';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

/*
const amountToStripeFormat = (amount) => {
  let amountConverted = amount.toString();
  if (amountConverted.length<3) {
    amountConverted = amountConverted+'00';
  }
  // Remove commas, dots, others.
  amountConverted = amountConverted.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
  console.log('amountToStripeFormat: amountConverted: ',amountConverted);
  return amountConverted;
}*/

const CheckoutForm = (props) => {
  const { t } = useTranslation('common');
  const promotional_code = JS.getCookie({cname: 'promotional_code'})
  const { urlReferer, payment } = props;
  const stripe = useStripe();
  const elements = useElements();
  const session = JS.getSession();
  const { account_tier } = session.account;
  const [channel, setChannel] = useState(session.custom.channels[0]);
  const prices = tierPrices();
  const tier_selected = prices.find(item => item.tier_id === account_tier);
  const [ clientSecret, setClientSecret ] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const { payment_to_email } = payment;
  const amount_stripe = tier_selected.tier_price; //amountToStripeFormat();
  const payment_to_channel = channel.channel_linkname;
  const [paymentStatus,setPaymentStatus] = useState(false);
  const [paymentIntentIdResult, setPaymentIntentIdResult] = useState();
  const [formAvailable, setFormAvailable] = useState(false);
  const [formData, setFormData] = useState({
    email: session.account.account_email,
    firstname: session.account.account_firstname,
    lastname: session.account.account_lastname
  });
  const [checked, setChecked] = React.useState(false);

  useEffect(()=>{
  });

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function sendEmail(data) {
    const {
      id,
      amount,
      payment_method_id,
      email,
      firstname,
      lastname,
      payment_to_channel,
      payment_to_email,
      payment_amount_net
    } = data;
    const tier_name = tier_selected.tier_name;
    const payload = {
      "to": email.toLowerCase(),
      "subject": "Pago realizado correctamente",
      "message": "<h2>Pago registrado</h2>"+
      "<p>¡Hola "+firstname+"!</p>"+
      "<p>Tu pago de plan se ha realizado correctamente.</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<h3>Datos de pago</h3>"+
      "<p>Destinatario: <span style='font-weight: 600;'>https://rapimeet.com</span></p>"+
      "<p>Email: "+email.toLowerCase()+"</p>"+
      "<p>Plan: "+tier_name+"</p>"+
      "<p>ID de pago: <span style='font-weight: 600;'>"+payment_method_id+"</span></p>"+
      "<p>Monto ingresado: <span style='font-weight: 600;'>€"+stripeAmountToDecimal(amount)+"</span></p>"+
      "</div>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "</div>"
    };
    const endpoint = `/sendHTML`;

    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setLoading(false);
      if(result) {
        setEndpointPaymentCreate(data);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getEndpointChannel = async(channel_linkname) => {
    const payload = {
      "channel_linkname": channel_linkname
    }
    const endpoint = `/channel`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { channel_id } = res.data.data;
      return channel_id;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  const setEndpointPaymentCreate = async (data) => {
    // setPaymentStatus(true);
    const channel_id = await getEndpointChannel(payment_to_channel);
    const { email, firstname, lastname, group } = formData;
    const amount_decimal = stripeAmountToDecimal(amount_stripe).replace(',','.');
    const payload = {
      "channel_id": channel_id,
      "payment_provider_type": "stripe",
      "payment_currency": "EUR",
      "payment_amount": amount_decimal,
      "customer_email": email,
      "payment_provider_data": data,
      "customer_data": {
        firstname: firstname,
        lastname: lastname
      }
    }
    const endpoint = `/payment/create`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setPaymentStatus(true);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email, firstname, lastname, group } = formData;
    if(email!=='' && firstname!=='' && lastname !=='') {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error) {
        setLoading(true);
        const { id } = paymentMethod;
        const amount_decimal =  parseFloat(stripeAmountToDecimal(amount_stripe));
        const payment_amount_net_decimal = amount_decimal-(amount_decimal*9)/100;
        const payment_amount_net = amountToStripeFormat(payment_amount_net_decimal.toString().replace('.', ','));
        const payload = {
          id,
          amount: amount_stripe,
          payment_method_id: id,
          email,
          firstname,
          lastname,
          payment_to_channel,
          payment_to_email,
          payment_amount_net
        };
        const endpoint = `/payment_stripe`;

        try {
          const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
          const {id, status} = res.data.data
          if (id && status === 'succeeded') {
            // Handle successful payment here
            setPaymentIntentIdResult(id);
            if(promotional_code!=='') {
              console.log('setEndpointPromotionalCode');
              setEndpointPromotionalCode(payload);
            } else {
              sendEmail(payload);
            }
          } else {
            // Handle unsuccessful, processing, or canceled payments and API errors here
            /* console.log('unsuccessful'); */
          }

          elements.getElement(CardElement).clear();
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      }
    }
  };

  async function setEndpointPromotionalCode(customer_data) {
    const data = {
      "promotional_code_code": promotional_code,
      "account_email": customer_data.email,
      "promotional_code_currency": "EUR",
      "promotional_code_amount": customer_data.payment_amount_net
    };
    const endpoint = `/promotional_code/create`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data.data;
      sendEmail(customer_data);
    } catch (err) {
      console.log(err)
    }
  };

  const goTo = () => {
    // window.location.href=urlReferer+'/pi='+JS.encodeString(paymentIntentIdResult);
    window.location.href=urlReferer;
  }

  if (paymentStatus) {
    return (
      <div>
        <Row>
          <Col>
            <Notification className='success'>¡Felicidades! El pago se ha realizado correctamente.</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="primary" label='Volver' action={()=>goTo()}/>
          </Col>
        </Row>
      </div>
    )
  }

  const validateForm = () => {
    const { email, firstname, lastname, group } = formData;
    if(email!=='' && firstname!=='' && lastname !=='' && checked) {
      return true;
    }
    return false;
  }

  return (
    <form onSubmit={(e) =>handleSubmit(e)}>
      {/* Product Information */}
        {(notification.status) ? (
          <Row>
            <Col>
              <Notification className={notification.className}>{notification.message}</Notification>
            </Col>
          </Row>) : null
        }
        {/*<Row>
          <Col>
            Destino: https://rapimeet.com/{group}
          </Col>
        </Row>*/}
        <Row>
          <Col>Nombre y apellido</Col>
          <Col>
            {formData.firstname} {formData.lastname}
          </Col>
        </Row>
        <Row>
          <Col>Email</Col>
          <Col>
            {formData.email}
          </Col>
        </Row>
        <Row>
          <Col>Plan</Col>
          <Col>
            {tier_selected.tier_name}
          </Col>
        </Row>
        <Row>
          <Col><H3>Total</H3></Col>
          <Col className='align-right'><H3><span className='text-gray plan-strikethrough space'>€{stripeAmountToDecimal(amount_stripe)}</span></H3></Col>
        </Row>
        <Row>
          <Col>
            <div className='check-wrapper'>
              <input
                type="checkbox"
                className='space'
                checked={checked}
                onChange={handleChange} 
              /> 
              <div className='check-label'>{t('sign_up.accept_terms_conditions')} <Link to={'/terms'} target="_blank" rel="noreferrer">Términos y condiciones</Link> y <Link to={'/privacy'} target="_blank" rel="noreferrer">Política de privacidad</Link></div>
            </div>
          </Col>
        </Row>
      <Row>
        <Col>
          <CardElement className="inp"/>
        </Col>
      </Row>
      {
        validateForm() ? (
          <button disabled={!stripe} className="btn primary">
            {loading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Pagar ahora'
            )}
          </button>
        ) 
        : (
          <button className="btn disabled">
            Pagar ahora
          </button>
        )
      }
      
    </form>
  );
};

export default CheckoutForm;
/*

import {PaymentElement} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  return (
    <form>
      <PaymentElement />
      <button>Submit</button>
    </form>
  );
};

export default CheckoutForm;

*/