import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { routes } from '../routes/Routes';
import './App.css';

/* eslint-disable */
declare var grovefy: any; // Declare global realtime communication
/* eslint-enable */

const Application: React.FunctionComponent<{}> = props => {

  useEffect(() => {
  }, [])

  return (
    <div>
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props: RouteComponentProps<any>) => (
                  <route.layout>
                    <route.component
                      name={route.name}
                      {...props}
                      {...route.props}
                    />
                  </route.layout>
                )}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default Application;