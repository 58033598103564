import React, {useState, useEffect} from 'react';
import { Row, Col, Notification, Input, Button, JS } from 'grovefy-ui-pack';
import './PaymentModal.css';
import { request, ContentTypes } from '../../store/api/api';

const PaymentModal = (props: any) => {
  const { close, save } = props;
  const session = JS.getSession();
  const [channel, setChannel] = useState(session.custom.channels[0]);
  const [formData, setFormData] = useState({
    amount: '',
  });
  const [amountIsValid,setAmountIsValid] = useState(false);
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [selectProvider, setSelectProvider] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(loading===false) {
      getEndpointPaymentProvider(channel.channel_id);
    }
  });

  const getEndpointPaymentProvider = async (channel_id) => {
    const payload = {
      channel_id
    };
    const endpoint = `/payment_provider`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const paymentProviderResult = res.data;
      if(paymentProviderResult.result==='true') {
        const provider_bizum = paymentProviderResult.data.find((item) => item.payment_provider_type==='bizum');
        const provider_bank_account = paymentProviderResult.data.find((item) => item.payment_provider_type==='bank_account');
        let newFormData = {};
        if(provider_bizum!==undefined) {
          newFormData.bizum_payment_provider_id = provider_bizum.payment_provider_id;
          newFormData.bizum_phone = provider_bizum.payment_provider_data.bizum_phone;        
        }
        if(provider_bank_account!==undefined) {
          newFormData.bank_account_payment_provider_id = provider_bank_account.payment_provider_id;
          newFormData.bank_account_fullname = provider_bank_account.payment_provider_data.bank_account_fullname;
          newFormData.bank_account_name = provider_bank_account.payment_provider_data.bank_account_name;
          newFormData.bank_account_number = provider_bank_account.payment_provider_data.bank_account_number;
          newFormData.bank_account_swift = provider_bank_account.payment_provider_data.bank_account_swift;         
        }
        setPaymentProvider(newFormData);
        setLoading(true);
      }
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const handleInputDecimalChange = (event) => {
    const amountFixed = event.target.value;
    if (!amountFixed || amountFixed.match(/^\d{1,}(\,\d{0,2})?$/)) {
      setFormData({
        ...formData,
        [event.target.name] : amountFixed
      })
      if((parseInt(amountFixed)>1) && (parseInt(amountFixed)<=1000)) {
        setAmountIsValid(true);
      } else {
        setAmountIsValid(false);
      }
    }
  }

  const saveModal = () => {
    save(formData);
  }

  const closeModal = () => {
    close();
  }

  const existPaymentProvider = () => {
    const paymentProviderCounter = paymentProvider ? Object.values(paymentProvider) : null;
    if(paymentProviderCounter && paymentProviderCounter.length>0) {
      return true;
    }
    return false;
  }

  if(paymentProvider===null) {
    return (
      <div className='w-100'>
        <div className='modal-header'>Solicitar pago</div>
        <div className='modal-content'>
          Loading...
        </div>
      </div>
    );
  }

  if(existPaymentProvider()===false) {
    return (
      <div className='w-100'>
        <div className='modal-header'>Solicitar pago</div>
        <div className='modal-content'>
          <Row>
            <Col>
              <Notification className='alert'>Debes configurar métodos de pago.</Notification>
            </Col>
          </Row>
        </div>  
        <div className='modal-bottom'>
          <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
          <a href='./dashboard/monetize' target='_blank'><Button className='primary' action={()=>null} label='Configurar'/></a>
        </div>
      </div>
    )
  }

  return (
    <div className='w-100'>
      <div className='modal-header'>Solicitar pago</div>
      <div className='modal-content'>
        <Row className='mb-column'>
          <Col className='label'>
            Envía a tu cliente la solicitud de pago con el importe a abonar 
          </Col>
          <Col className='align-right'>
            <input type='text' placeholder='0,00' className='inp-amount' name='amount' value={formData.amount} onChange={(event)=>handleInputDecimalChange(event)}/>
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        {amountIsValid ?
          <Button className='primary' action={()=>saveModal()} label='Solicitar pago'/>
          : <Button className='disabled' action={()=>null} label='Solicitar pago'/>
        }
      </div>
    </div>
  )
}

export default PaymentModal;
