import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Section, Row, Col, H3, Table, Thead, Tr, Th, Tbody, Td, Input, Button, Notification, JS } from 'grovefy-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { stripeAmountToDecimal } from '../../../../lib/Lib';

function PromotionalCodeAdmin() {
  const { promotional_code_code } = useParams();
  const [promotionalCodeList, setPromotionalCodeList] = useState();
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: ''
  }) 
  const [notification, setNotification] = useState({status: false, style: '', message: ''});

  useEffect(()=>{
    console.log('promotionalCodeList:', promotionalCodeList);
    if(!promotionalCodeList) {
      console.log('promotionalCodeList: ',promotionalCodeList);
      getEndpointPromotionalCodeList(promotional_code_code);
    }
  })

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function getEndpointPromotionalCodeList(promotional_code) {
    const data = {
      "promotional_code_code": promotional_code
    };
    const endpoint = `/promotional_code`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const resultPromotionalCodeList = res.data.data;
      setPromotionalCodeList(resultPromotionalCodeList);
    } catch (err) {
      console.log(err)
    }
  };

  async function sendPromotionalCodeToAdminEmail() {
    const { email, firstname, lastname } = formData;
    const url = `https://rapimeet.com/promotional/${JS.encodeString(promotional_code_code)}`
    
    const payload = {
      "to": email.toLowerCase(),
      "subject": "Programa afiliado",
      "message": "<h2>Se ha creado tu código promocional</h2>"+
      "<p>¡Hola "+firstname+"!</p>"+
      "<p>Tu código promocional <b>"+promotional_code_code+"</b> se ha activado para recibir ingresos.</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<h3>Administrador</h3>"+
      "<p>Nombre y apellido: <span style='font-weight: 600;'>"+firstname+" "+lastname+"</span></p>"+
      "<p>Email: <span style='font-weight: 600;'>"+email+"</span></p>"+
      "</div>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<a style='background-color: #0057FF; color: #FFFFFF; font-weight: 600; border-radius: 30px; padding: 10px 40px; text-decoration: none;' href='"+url+"'>Ver mis ingresos</a></div>"
    };
    const endpoint = `/sendHTML`;

    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setNotification({
        status: true,
        className: 'success',
        message: 'El email se ha enviado correctamente.'
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendEmailSubmit = async (e) => {
    e.preventDefault();
    console.log('formData: ',formData);
    sendPromotionalCodeToAdminEmail();
  }

  return (
    <div className='payment-wrapper'>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <H3>Partner: {promotional_code_code}</H3>
          </Col>
        </Row>
      </Section>
      <Section>
        <Row>
          <Col>
            Enviar email con URL de acceso a lista de cobros por Código promocional:
          </Col>
        </Row>
        <form onSubmit={(e) =>handleSendEmailSubmit(e)}>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <Input type='text' name='firstname' value={formData.firstname} placeholder='Nombre' onChange={handleInputChange}/>
            </Col>
            <Col>
              <Input type='text' name='lastname' value={formData.lastname} placeholder='Apellido' onChange={handleInputChange}/>
            </Col>
            <Col>
              <Input type='email' name='email' value={formData.email} placeholder='Email influencer' onChange={handleInputChange}/>
            </Col>
            <Col>
              <Button label='Send email' action={()=>null}></Button>
            </Col>
          </Row>
        </form>
      </Section>
      <Section>
        <Row>
          <Col>
            <div className='table-wrapper'>
              <div className='table-scroll'>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Email</Th><Th>Fecha</Th><Th>Monto</Th><Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {promotionalCodeList ? promotionalCodeList.map((item, index)=>{
                      const {account_email, created_at, promotional_code_currency ,promotional_code_amount} = item;
                      const amount = stripeAmountToDecimal(promotional_code_amount);
                      return (
                        <Tr key={index}>
                          <Td>{account_email}</Td><Td>{created_at}</Td><Td>{promotional_code_currency} {amount}</Td>
                        </Tr>
                      )
                    }) : null}
                  </Tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default PromotionalCodeAdmin;