import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { Section, Row, Col, H5, Footer } from 'grovefy-ui-pack';
import * as JSLib from '../../../lib/Lib';
import './Welcome.css';
import callMP3 from '../../../assets/sound/call.mp3';

/* eslint-disable */
declare var grovefy: any; // Declare global realtime communication
// const host = 'http://localhost:8081';
const host = JSLib.getHost();// location.host==='localhost:8081' ? 'http://localhost:8081' : 'https://'+location.host;
// const host = 'https://rapimeet.com';

function Welcome() {
  const [audio] = useState(new Audio(callMP3));
  const { channel_linkname } = useParams();
  // const rapimeet_link = 'http://localhost:8081/room?group=oficina_12&from=customer_1677013730495&to=support&call=share-screen';
  
  const [channel, setChannel] = useState();
  const [members, setMembers] = useState();
  const [grovefyInitialized, setGrovefyInitialized] = useState(false);
  // const { session } = meet_data[0];
  // https://tqwbp4hrfg.execute-api.eu-central-1.amazonaws.com/dev/get-channel
  const [callStatus, setCallStatus] = useState('stop');
  const [showBtnCallNow, setShowBtnCallNow] = useState(false);

  const getToken = () => {
    var today = new Date();
    var dd = today.getDate();
  
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    var time = today.getTime();
    if(dd<10) {
      dd='0'+dd;
    } 
  
    if(mm<10) {
      mm='0'+mm;
    } 
    today = yyyy+'-'+mm+'-'+dd+'-'+time;
    //return today;
    return time;
  }
  
  const getCustomerCookie = () => {
    const grovefy_customer_id = grovefy.getCookie('grovefy_customer_id');
    if(!grovefy_customer_id) {
      grovefy.setCookie('grovefy_customer_id', 'customer_'+getToken());
    }
    return grovefy_customer_id;
  }
  
  const getToCookie = () => {
    const grovefy_to_id = grovefy.getCookie('to');
    if(!grovefy_to_id) {
      grovefy.setCookie('to', 'support');
    }
    return grovefy_to_id;
  }

  const config = {
    group: channel_linkname,
    from: getCustomerCookie(),
    to: getToCookie(), // ['superadmin'] / ['info@example.com']  / ['123456'] / ['tablet', 'iphone']
    data: {
      function: 'initializeConnection',
      parameters: {}
    }
  };  

  useEffect(() => {
    if(channel===undefined) {
      getChannel();
    }
  });

  useEffect(()=> {
    if((grovefyInitialized===false)&&(channel)) {
      grovefy.init(config);
      setTimeout(() => {
        const data = {
          function: 'initialize',
          parameters: {
            grovefy_customer_id: getCustomerCookie()
          }
        };
        grovefy.send(data);
        setShowBtnCallNow(true);
      }, 2000);
      setGrovefyInitialized(true);
    }
  }, [channel]);

  async function getMember(channel_id) {
    const data = {
      "channel_id" : channel_id.toString()
    };
    const endpoint = `/member/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      setMembers(result);
    } catch (err) {
      console.log(err)
    }
  };

  // Get branch related to the meet
  async function getChannel() {
    const data = {
      "channel_linkname" : channel_linkname
    };
    const endpoint = `/channel`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data.data;
      getMember(result.channel_id);
      setChannel(result);
    } catch (err) {
      console.log(err)
    }
  };

  const handleRoom = (callType) => {
    const roomLink = `${host}/room?group=${config.group}&from=${config.from}&to=${config.to}&call=${callType}`;
    // window.open(roomLink);
    // window.location.href=roomLink;
    const roomLinkTo = `${host}/room?group=${config.group}&from=${config.to}&to=${config.from}`;
    const data = {
      function: 'openRequestRoom',
      parameters: {
        roomLink: roomLinkTo
      }
    };
    grovefy.send(data);
    setCallStatus('calling');
    enableLoop();
  }

  const openRoom = () => {
    const roomLink = `${host}/room?group=${config.group}&from=${config.from}&to=${config.to}&call=share-screen`;
    window.location.href=roomLink;
  }

  const stopCall = () => {
    setCallStatus('stop');
    disableLoop();
  }

  const renderButtonCall = () => {
    const to = members.data[0].account_id;
    const from = 'customer_'+JSLib.createToken();
    // grovefy.setCookie('grovefy_customer_id', from);
    const url =`${host}/room?group=${channel_linkname}&from=${from}&to=${to}&call=share-screen`
    if (callStatus==='calling') {
      return (
        <Row>
          <Col>
            <div>
              <button className='btn-call-now' onClick={()=>stopCall()} >Llamando...</button>
            </div>
          </Col>
        </Row>
      )
    }

    return (
      <Row>
        <Col>
          <div>
            <button className='btn-call-now' onClick={()=>handleRoom('share-screen')} >Llamar ahora</button>
          </div>
        </Col>
      </Row>
    )
  }

  const renderButtonCalendar = () => {
    const { channel_linkname } = channel;
    const url =`${host}/schedule/${channel_linkname}`
    return (
      <Row>
        <Col>
          <a href={url}>
            <button className='btn-large'>Agendar reunión</button>
          </a>
        </Col>
      </Row>      
    )
  }

  // Calling
  grovefy.receive = (data_received) => {
    const { group, from, to, data } = data_received;

    /*console.log('group: ', group);
    console.log('from: ', from);
    console.log('to', to);*/
    switch(data.function) {
      case 'openRoom':{
        openRoom();
        break;
      }
      default: {
        // console.log('Default: data: ', data);
      }
    }
  }

  const renderChannelConnector = () => {
    const { channel_linkname, channel_name, channel_description, calendar_status } = channel;
    return (
      <div className='meet-contact'>
        <Row className='mb-0'>
          <Col className='align-center'>
            <div className='channel-description'>
              <div className='title'><H5>{channel_name}</H5></div>
              <div>{channel_description}</div>
              <div className='link-wrapper'>https://rapimeet.com/<span className='linkname'>{channel_linkname}</span></div>            
            </div>
          </Col>
        </Row>
        <Row className='mb-0'>
          <Col>{/*<H4>Llamar a</H4>*/}
            <div className='note'>Haz click en "Llamar ahora" para iniciar la llamada de voz, videollamada, compartir pantalla o chat.</div>
          </Col>
        </Row>

        {/*<Row>
          <Col className='align-center'>
            <div className='icons-communications'></div>
          </Col>
    </Row>*/}
        {(members && showBtnCallNow===true) ? renderButtonCall() : null}
        {calendar_status ? renderButtonCalendar() :null }
      </div>
    )
  }

  const getIconStatus = (callStatus) => {
    switch (callStatus) {
      case 'calling':
        return <div className='icon-status-calling'></div>
      case 'stop':
        return <div className='icon-status-stop'></div>
      default:
        break;
    }
  }

  function enableLoop() { /*w  w w. j a v  a 2s . c  o  m*/
    audio.loop = true;
    audio.load();
    audio.play();
  } 

  function disableLoop() { 
    audio.loop = false;
    audio.load();
  } 

  return (
    <div className='meet-wrapper home'>
      <div className='meet-header'><div className='customer-logo'></div></div>
      <div className='meet-container'>
        <Section maxWidth='small' className='box-center'>
          <div className='welcome-wrapper'>
            <div>
              <Row>
                <Col className='align-center'>
                  <div className='calling-box'>
                    <div className='calling-wrapper'>
                      {getIconStatus(callStatus)}
                      <div className='icon-calling-bg'></div>
                    </div>
                    {(channel) ? 
                      renderChannelConnector() 
                      : <div>El contacto "{channel_linkname}" no existe.</div>
                    } 
                  </div>
                </Col>
              </Row>
            </div>
            <div className='meet-info'>
              <a href='https://rapimeet.com'>Rapimeet.com</a> 
              <div>Monta tu negocio de sesiones online en minutos.</div>
            </div>
          </div>
        </Section>
      </div>
      <Footer
        companyName='Rapimeet, Todos los derechos reservados'
        linkPrivacy='./privacy'
        linkTerms='./terms'
      ></Footer>
    </div>
  );
}

export default Welcome;