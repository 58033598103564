import React, { useEffect, useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Section, Row, Col, Button, Input, H4, Notification, JS } from 'grovefy-ui-pack';
import { amountToStripeFormat } from '../../../lib/Lib';
import { request, ContentTypes } from '../../../store/api/api';

/*
const amountToStripeFormat = (amount) => {
  let amountConverted = amount.toString();
  if (amountConverted.length<3) {
    amountConverted = amountConverted+'00';
  }
  // Remove commas, dots, others.
  amountConverted = amountConverted.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
  console.log('amountToStripeFormat: amountConverted: ',amountConverted);
  return amountConverted;
}*/

const CheckoutCustomerForm = (props) => {
  const { payment } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [ clientSecret, setClientSecret ] = useState('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  
  const amount = payment.amount_stripe;
  const payment_to_email = 'cjgaraycochea@gmail.com';
  const amount_stripe = amountToStripeFormat(amount);
  const payment_to_channel = 'tx206';
  const buyer = {
    email: payment.email,
    firstname: payment.firstname,
    lastname: payment.lastname,
    product_name: payment.product_name
  }

  const group = payment_to_channel;
  const [paymentStatus,setPaymentStatus] = useState(false);
  const [paymentIntentIdResult, setPaymentIntentIdResult] = useState();

  const [formData, setFormData] = useState({
    email: buyer.email,
    firstname: buyer.firstname,
    lastname: buyer.lastname,
    product_name: buyer.product_name
  });

  useEffect(() => {
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email, firstname, lastname, group } = formData;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    setLoading(true);

    if (!error) {
      const { id } = paymentMethod;
      const payload = {
        id,
        amount: amount_stripe,
        payment_method_id: id,
        email,
        firstname,
        lastname,
        payment_to_channel,
        payment_to_email
      };
      const endpoint = `/payment_stripe`;
      
      try {
        const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });

        //const { data } = await axios.post("http://localhost:3020/pay", payload);
        const {id, status} = res.data.data
        if (id && status === 'succeeded') {
          // Handle successful payment here
          setPaymentIntentIdResult(id);
          setPaymentStatus(true);
        } else {
          // Handle unsuccessful, processing, or canceled payments and API errors here
          /* console.log('unsuccessful'); */
        }
        {/*
        (async () => {

 
        })();*/}

        elements.getElement(CardElement).clear();
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  const goToMeeting = () => {
    window.location.href='https://rapimeet.com/';
    // window.location.href='https://rapimeet.com/'+'&pi='+JS.encodeString(paymentIntentIdResult);
  }

  console.log(!stripe || loading);

  if (paymentStatus) {
    return (
      <div>

        <Row>
          <Col>
            <Notification className='success'>¡Felicidades! El pago se ha realizado correctamente, ahora podrás continuar con tu reunión online.</Notification>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="primary" label='Volver al meeting' action={()=>goToMeeting()}/>
          </Col>
        </Row>
      </div>
    )
  }


  return (
    <form onSubmit={(e) =>handleSubmit(e)}>
      {/* Product Information */}
        {(notification.status) ? (
          <Row>
            <Col>
              <Notification className={notification.className}>{notification.message}</Notification>
            </Col>
          </Row>) : null
        }
        <Row>
          <Col>
            Usuario: {formData.firstname} {formData.lastname} - {formData.email}
          </Col>
        </Row>
        <Row>
          <Col>
            Producto: {formData.product_name} €{amount}
          </Col>
        </Row>
        <Row>
          <Col><H4>Total</H4></Col>
          <Col className='align-right'><H4><span className='text-gray plan-strikethrough space'>€{amount}</span></H4></Col>
        </Row>
      <Row>
        <Col>
          <CardElement className="inp"/>
        </Col>
      </Row>
      <button disabled={!stripe} className="btn primary">
        {loading ? (
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          'Pagar ahora'
        )}
      </button>
    </form>
  );
};

export default CheckoutCustomerForm;
/*

import {PaymentElement} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  return (
    <form>
      <PaymentElement />
      <button>Submit</button>
    </form>
  );
};

export default CheckoutForm;

*/