import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H1, H5, Input, Button, Notification, Loading, JS } from 'grovefy-ui-pack';
import * as JSLib from '../../../../../lib/Lib';
import { request, ContentTypes } from '../../../../../store/api/api';

function PaymentSettings() {
  const session = JS.getSession();
  const {account_email} = session.account;
  const [channel, setChannel] = useState(session.custom.channels[0]);
  const [formData, setFormData] = useState({});
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [loading, setLoading] = useState(false);
  const [loadingBizum, setLoadingBizum] = useState(false);
  const [loadingBankAccount, setLoadingBankAccount] = useState(false);
  //const [paymentPovider, setPaymentPovider] = useState();

  useEffect(()=>{
    if(loading===false) {
      getEndpointPaymentProvider(channel.channel_id);
    }
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  const getEndpointPaymentProvider = async (channel_id) => {
    const payload = {
      channel_id
    };
    const endpoint = `/payment_provider`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const paymentPoviderResult = res.data;
      if(paymentPoviderResult.result==='true') {
        const provider_bizum = paymentPoviderResult.data.find((item) => item.payment_provider_type==='bizum');
        const provider_bank_account = paymentPoviderResult.data.find((item) => item.payment_provider_type==='bank_account');
        let newFormData = {};
        if(provider_bizum!==undefined) {
          newFormData.bizum_payment_provider_id = provider_bizum.payment_provider_id;
          newFormData.bizum_phone = provider_bizum.payment_provider_data.bizum_phone;        
        }
        if(provider_bank_account!==undefined) {
          newFormData.bank_account_payment_provider_id = provider_bank_account.payment_provider_id;
          newFormData.bank_account_fullname = provider_bank_account.payment_provider_data.bank_account_fullname;
          newFormData.bank_account_name = provider_bank_account.payment_provider_data.bank_account_name;
          newFormData.bank_account_number = provider_bank_account.payment_provider_data.bank_account_number;
          newFormData.bank_account_swift = provider_bank_account.payment_provider_data.bank_account_swift;         
        }
        setFormData(newFormData);
        setLoading(true);
      }
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const setEndpointPaymentProviderCreate = async (payload) => {
    const endpoint = `/payment_provider/create`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const paymentPoviderResult = res.data;
      if(paymentPoviderResult.result==='true') {
        setNotification({
          payment_provider_type: payload.payment_provider_type,
          status: true,
          className: 'success',
          message: 'Los datos se han guardado correctamente.'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setEndpointPaymentProviderUpdate = async (payload) => {
    const endpoint = `/payment_provider/update`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const paymentPoviderResult = res.data;
      if(paymentPoviderResult.result==='true') {
        setNotification({
          payment_provider_type: payload.payment_provider_type,
          status: true,
          className: 'success',
          message: 'Los datos se han guardado correctamente.'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleBizumSubmit(event) {
    event.preventDefault();
    if(formData.bizum_phone===undefined || formData.bizum_phone.length!==9) {
      setNotification({
        payment_provider_type: 'bizum',
        status: true,
        className: 'danger',
        message: 'El número de móvil debe contener 9 dígitos.'
      });
    }
    else {
      let payload = {
        "channel_id": channel.channel_id,
        "payment_provider_type": "bizum",
        "payment_provider_data": {
          "bizum_phone": formData.bizum_phone
        }
      };
      if(formData.bizum_payment_provider_id!==undefined) {
        payload.payment_provider_id = formData.bizum_payment_provider_id;
        setEndpointPaymentProviderUpdate(payload);
      } else {
        setEndpointPaymentProviderCreate(payload);
      }
    }
  }

  async function handleBankAccountSubmit(event) {
    event.preventDefault();
    if(
      formData.bank_account_fullname === undefined
      || formData.bank_account_number === undefined
      || formData.bank_account_fullname.length<5
      || formData.bank_account_number.length<10
    ) {
      setNotification({
        payment_provider_type: 'bank_account',
        status: true,
        className: 'danger',
        message: 'Complete correctamente los campos.'
      });
    }
    else {
      const payload = {
        "channel_id": channel.channel_id,
        "payment_provider_type": "bank_account",
        "payment_provider_data": {
          "bank_account_fullname": formData.bank_account_fullname,
          "bank_account_name": formData.bank_account_name,
          "bank_account_number": formData.bank_account_number,
          "bank_account_swift": formData.bank_account_swift
        }
      };
      if(formData.bank_account_payment_provider_id!==undefined) {
        payload.payment_provider_id = formData.bank_account_payment_provider_id;
        setEndpointPaymentProviderUpdate(payload);
      } else {
        setEndpointPaymentProviderCreate(payload);
      }
    }
  }

  return (
    <div>
      <Section className='medium'>
        <Row>
          <Col>
            <H5>Bizum (España)</H5>
          </Col>
        </Row>
        <Row>
          <Col>
            Bizum es un proveedor de servicios de pago instántaneo, solo válido en España. 
            Al completar el siguiente campo aparecerá como opción de pago a tus clientes.
          </Col>
        </Row>
        <form onSubmit={ e => handleBizumSubmit(e)}>
          {(notification.status && notification.payment_provider_type==='bizum') ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <div>
            <Row className='mb-column'>
              <Col className='label'>
                Número de móvil vinculado a Bizum
              </Col>
              <Col className='input-telephone'>
                +34 <Input className='flex-line' type='number' name='bizum_phone' value={formData.bizum_phone} placeholder='6XX XX XX XX' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type='submit' label='Guardar' className='primary' loading={loadingBizum} action={()=>null} />
              </Col>
            </Row>
          </div>
        </form>
      </Section>
      <Section className='medium'>
        <Row>
          <Col>
            <H5>Solicitar pago a Cuenta Bancaria</H5>
          </Col>
        </Row>
        <Row>
          <Col>
            Completa los siguientes para que tu Cuenta Bancaria se muestre a tus clientes.
          </Col>
        </Row>
        <form onSubmit={ e => handleBankAccountSubmit(e)}>
          {(notification.status && notification.payment_provider_type==='bank_account') ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <div>
            <Row className='mb-column'>
              <Col className='label'>
                Nombre y apellido del titular de la cuenta bancaria
              </Col>
              <Col>
                <Input type='text' name='bank_account_fullname' value={formData.bank_account_fullname} placeholder='Nombre y apellido' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row className='mb-column'>
              <Col className='label'>
                Nombre de banco
              </Col>
              <Col>
                <Input type='text' name='bank_account_name' value={formData.bank_account_name} placeholder='Nombre de banco' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row className='mb-column'>
              <Col className='label'>
                IBAN (sin espacios)
              </Col>
              <Col>
                <Input type='text' name='bank_account_number' value={formData.bank_account_number} placeholder='IBAN' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row className='mb-column'>
              <Col className='label'>
                SWIFT/BIC (opcional para pagos internacionales)
              </Col>
              <Col>
                <Input type='text' name='bank_account_swift' value={formData.bank_account_swift} placeholder='SWIFT/BIC' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type='submit' label='Guardar' className='primary' loading={loadingBankAccount} action={()=>null} />
              </Col>
            </Row>
          </div>
        </form>
      </Section>
    </div>
  )
}

export default PaymentSettings;
