import React, { useEffect, useRef, useState } from 'react';
import './Admin.css';
import { Row, Col, H5, JS, Section } from 'grovefy-ui-pack';
import callMP3 from '../../../assets/sound/new.mp3';
import * as JSLib from '../../../lib/Lib';
import {request, ContentTypes} from '../../../store/api/api';

/* eslint-disable */
declare var grovefy: any; // Declare global realtime communication

function Admin() {
  const [audio] = useState(new Audio(callMP3));
  const session = JS.getSession();
  const [channel, setChannel] = useState(session.custom.channels[0]);
  const [formData, setFormData] = useState({});
  const [grovefyInitialized, setGrovefyInitialized] = useState(false);
  const messagesCookie = JSLib.getMessagesCookie();
  const [messages, setMessages] = useState(messagesCookie); 
  const group = session.custom.channels[0].channel_linkname;
  const customerListCookie = localStorage.getItem('customerList') ? JSON.parse(JS.decodeString(localStorage.getItem('customerList'))) : [];
  const [customersList, setCustomersList] = useState(customerListCookie);
  const [customerId, setCustomerId] = useState(null);
  const chatWrapper = useRef();
  const [chatWrapperHeight, setChatWrapperHeight] = useState();
  const [showChat, setShowChat] = useState(false);
  grovefy.setCookie('from', 'support');
  const [config, setConfig] = useState({
    group: group,
    from: grovefy.getCookie('from'),
    to: '', // ['superadmin'] / ['info@example.com']  / ['123456'] / ['tablet', 'iphone']
    data: {
      function: '',
      parameters: {}
    }
  });
  const [refreshState, setRefreshState] = useState(0);
  const [soundRing, setSoundRing] = useState(true);

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  useEffect(() => {
    if(localStorage.getItem('new')==='true') {
      localStorage.setItem('new', 'false');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    getEndpointGeolocation();
    if(grovefyInitialized===false) {
      grovefy.init(config);
      setGrovefyInitialized(true);
      checkCustomerConnected();
      getEndpointMessages();
      getEndpointChat();
    }

    getMinutesDifference('2022/11/09, 12:00', '2022/11/09, 12:30');

    if(chatWrapperHeight===undefined) {
      getChatResize();
    }
    window.addEventListener("resize", getChatResize);
  });

  const handleSendMessage = () => {
    const data = {
      function: 'chat',
      parameters: { 
        'message': formData.message
      }
    };
    grovefy.send(data);

    // Message screen
    const new_message = {
      to: customerId,
      author: config.from,
      message: formData.message
    };
    const data_message = {
      author: new_message.author,
      to: new_message.to,
      message: new_message.message
    }
    setEndpointMessage(data_message);
    addMessage(new_message);
  }

  const addMessage = (new_message) => {
    new_message.created_at = JSLib.getTimestampNow();
    new_message.created_at_localtime = JSLib.timestampToDate(new_message.created_at);
    const messages_updated = messages.concat(new_message);
    setMessages(messages_updated);
    JSLib.setMessagesCookie(new_message);
    // Move to bottom of .messages
    setTimeout(() => {
      const elem = document.querySelector(`.messages`);
      elem.scrollTo(0, elem.scrollHeight);
    }, 200);
  }

  const setEndpointMessage = async (message) => {
    const payload = {
      "channel_id": channel.channel_id,
      "message_author": message.author,
      "message_to": message.to,
      "message_content": message.message
    };
    const endpoint =`/message/create`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const data = res.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  const setEndpointChatCreate = async (chat_new) => {
    const payload = {
      "channel_id": chat_new.channel_id,
      "message_to": chat_new.message_to,
      "status": chat_new.status,
      "chat_data": chat_new.chat_data
    }
    const endpoint =`/chat/create_unique`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const data = res.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  const getEndpointMessages = async () => {
    const payload = {
      "channel_id": channel.channel_id
    };
    const endpoint =`/message`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const messages_db = res.data.data;
      const messages_result = messages_db.map((messageItem)=> {
        const created_at_localtime = JSLib.timestampToDate(messageItem.created_at); //new Date(messageItem.created_at*1000);
        return {
          author: messageItem.message_author,
          component: "",
          created_at: messageItem.created_at,
          created_at_localtime: created_at_localtime,
          message: messageItem.message_content,
          to: messageItem.message_to
        }
      })
      setMessages(messages_result);
    } catch (error) {
      console.log(error);
    }
  }

  const getEndpointChat = async () => {
    const payload = {
      "channel_id": channel.channel_id
    };
    const endpoint =`/chat`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const chat_db = res.data.data;
      const chat_result = chat_db.map((chatItem)=> {
        return {
          to: chatItem.message_to,
          last_connection: JSLib.timestampToDate(chatItem.created_at), // date format
          read: chatItem.status,
          chat_data: chatItem.chat_data,
          chat_ip_client: chatItem.chat_ip_client,
          chat_location: chatItem.chat_location,
        }
      })
      refreshCustomerList(chat_result);
    } catch (error) {
      console.log(error);
    }
  }

  const chatSanitize = (chat_order) => {
    let chat_clear = [];
    chat_order.forEach((messageItem)=> {
      if(!chat_clear.find((item)=> item.to===messageItem.to)) {
        chat_clear.push(messageItem);
      }
    });
    return chat_clear;
  }

  const refreshCustomerList = (chat_data) => {
    const chat_result = chatSanitize(chat_data);
    setCustomersList(chat_result);
    localStorage.setItem('customerList',JS.encodeString(JSON.stringify(chat_result)));
  }

  const getEndpointGeolocation = async () => {
    const payload = {};
    const endpoint =`/geolocation`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const result_data = res.data;
    } catch (error) {
      console.log(error);
    }
  }

  const setEndpointChatUpdate = async (message_to) => {
    const payload = {
      "channel_id": channel.channel_id,
      "message_to": message_to,
      "status": "read"
    };
    const endpoint =`/chat/update`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const data = res.data;
    } catch (error) {
      console.log(error);
    }
  }

  const chat = (from, data_received) => {
    const new_message = {
      to: customerId,
      author: from,
      message: data_received.message
    };
    addMessage(new_message);
    if(from !== customerId) {
      // updateCustomerLastConnection(from, 1);
      updateCustomerLastConnection(from, 'unread');
    }
  }

  const insertCustomersList = (grovefy_customer_id, parameters) => {
    const compare = customersList.find((item)=>item.to === grovefy_customer_id);
    if(!customersList.find((item)=>item.to === grovefy_customer_id)) {
      const data_item = parameters.from_predefined ? JSLib.decodeToken(parameters.from_predefined) : null;
      const new_customer_item = {
        to: grovefy_customer_id,
        last_connection: new Date().toLocaleString(), // date format
        read: 'read',
        chat_data: JSON.parse(data_item)
      };
      const chat_new = {
        channel_id: channel.channel_id,
        message_to: grovefy_customer_id,
        status: 'read',
        chat_data: JSON.parse(data_item)
      };
      setEndpointChatCreate(chat_new);
      let customersListUpdated = JSON.parse(JSON.stringify(customersList));
      customersListUpdated.unshift(new_customer_item);
      refreshCustomerList(customersListUpdated)
      setTimeout(() => {
        const elem = document.querySelector(`.customers-items`);
        elem.scrollTo(0, 0);
      }, 200);
      setTimeout(() => {
        getEndpointChat();  
      }, 1000);
      setRefreshState(Math.floor(Math.random()));
    }
  }

  const updateCustomerLastConnection = (grovefy_customer_id, read) => {
    const customersListUpdated = JSON.parse(JSON.stringify(customersList));
    let foundIndex = customersListUpdated.findIndex(item => item.to === grovefy_customer_id);
    if(customersListUpdated[foundIndex].read ==='unread') {
      customersListUpdated[foundIndex].read = read;
      // setCustomersList(customersListUpdated);
      refreshCustomerList(customersListUpdated)
    }
  }

  const chatSelected = (to) => {
    selectCustomerId(to);
    setEndpointChatUpdate(to);
    // updateCustomerLastConnection(to, 0);
    updateCustomerLastConnection(to, 'read');
    setTimeout(() => {
      const elem = document.querySelector(`.messages`);
      elem.scrollTo(0, elem.scrollHeight);
    }, 200);
    document.title = 'Rapimeet';
    // showChat
    setShowChat(!showChat);
  }

  const selectCustomerId = (to) => {
    setCustomerId(to); // Define chat selected in state
    grovefy.setCookie('to', to); // Set 'to' in grovefy
  }

  const initialize = (from, parameters) => {
    insertCustomersList(from, parameters)
  }

  const handleOpenRoomLink = (data_received) => {
    const { roomLink } = data_received;
    const data = {
      function: 'openRoom',
      parameters: {}
    };
    grovefy.send(data);
    window.open(roomLink);
  }

  const roomButtons = (data_received) => (
    <div className='message-full-content'>
      <div>
        <div className='calling-box'>
          <div class="calling-wrapper">
            <div className='icon-status-stop'></div>
            <div className='icon-calling-bg'></div>
          </div>
          <div className='cta btn-group'>
            <button className='btn-open-room' onClick={()=>handleOpenRoomLink(data_received)}>Atender llamada</button>
          </div>
        </div>
      </div>
    </div>
  );

  const openRequestRoom = (from, data_received) => {
    const new_message = {
      to: from,
      author: from,
      message: 'Recibiendo llamada...',
      component: roomButtons(data_received)
    };
    addMessage(new_message);
    // Show Unread
    if(from !== customerId) {
      // updateCustomerLastConnection(from, 1);
      updateCustomerLastConnection(from, 'unread');
    }
  }

  const openRequestPayment = (from, data_received) => {
    const new_message = {
      to: from,
      author: from,
      message: 'Pago recibido...',
      component: roomButtons(data_received)
    };
    addMessage(new_message);

    // Show Unread
    if(from !== customerId) {
      // updateCustomerLastConnection(from, 1);
      updateCustomerLastConnection(from, 'unread');
    }
  }

  grovefy.receive = (data_received) => {
    const { group, from, to, data } = data_received;
 
    switch(data.function) {
      case 'initialize':{
        initialize(from, data.parameters);
        break;
      }
      case 'chat':{
        initialize(from, data.parameters);
        chat(from, data.parameters);
        break;
      }
  /*    case 'openRequestShareScreen': {
        openRequestShareScreen(from, data.parameters);
        break;
      }
      case 'openShareScreen':{
        openShareScreen(from, data.parameters);
        break;
      }*/
      case 'openRequestRoom':{
        openRequestRoom(from, data.parameters);
        break;
      }
      case 'openRequestPayment': {
        openRequestPayment(from, data.parameters);
      }
      case 'reload':{
        // this.ready();
        // console.log('reload');
        break; 
      }
      default: {
        // console.log('Default: data: ', data);
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      setTimeout(() => {
        setFormData({
          ...formData,
          ['message']: ''
        })        
      }, 100);
    }
  }

  const convertHTML = (data) => {
    return <div dangerouslySetInnerHTML={{__html: `${data}`}} />
  }

  const checkCustomerConnected = () => {
    setTimeout(()=> {
      const data = {
        function: 'clear',
        parameters: {}
      };
      grovefy.send(data);
      // setCustomersList([]);
    }, 2000);
  }

  // get minutes of difference
  const getMinutesDifference = (dateA, dateB) => {
    var startTime = new Date(dateA); 
    var endTime = new Date(dateB);
    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    // console.log('resultInMinutes: ', resultInMinutes);
  }

  const getChatResize = () => {
    if(chatWrapper!==null && chatWrapper?.current!==null) {
      const chatHeight = chatWrapper?.current.offsetHeight;
      setChatWrapperHeight(chatHeight-138);
      setTimeout(() => {
        const elem = document.querySelector(".messages");
        elem.scrollTo(0, elem.scrollHeight);
      }, 200);      
    }
  }

  const circleNewMessage = (item) => {
    document.title = (item.read) ? "Rapimeet (+1)" : "Rapimeet";
    return (<div className='customer-new-message'></div>);
  }

  const renderCustomerList = (item) => {
    const { chat_data } = item;
    return (
      <div className='chat-data-item'>
        <div>{chat_data.fullname}</div>
        <div className='session'>{chat_data.email}</div>
        <div className='session'>{`Fecha de reunion: ${JSLib.convertTime(chat_data.time_session)}`}</div>
      </div>
    )
  }

  const renderCustomerLocation = (item) => {
    const { chat_location, chat_ip_client } = item;
    const { city, state, country } = chat_location;
    return (
      <div>
        {`${city}, ${state}, ${country} / IP:${chat_ip_client}`}
      </div>
    )
  }

  const renderCustomerDate = (item) => {
    const { last_connection } = item;
    return (
      <div>
        {`${last_connection}hs`}
      </div>
    );
    /*const created_at = JSLib.getTimestampNow();
    const created_at_localtime = JSLib.timestampToDate(created_at);
    let day_to_today = '';
    if(last_connection.substring(0,10)===created_at_localtime.substring(0,10)) {
      day_to_today = 'Hoy';
    }
    return (
      <div>
        {day_to_today!=='' ? day_to_today : `${last_connection}hs`}
      </div>
    )*/
  }

  const renderCustomerMessageCounter = (item) => {
    let messagesCustomer = [];
    const customerId= item.to;
    messages.forEach((messageItem)=>{
      const messageExist = ((((messageItem.author==='support')&&(customerId===messageItem.to))||(messageItem.author===customerId))||((messageItem.author!=='support') && customerId===messageItem.author)) ? messageItem : null 
      if(messageExist) {
        messagesCustomer.push(messageItem);
      }
    })
    if(messagesCustomer.length>0) {
      return <div className='item text-small message-counter-wrapper'><div className='message-counter'>{messagesCustomer.length}</div></div>
    }
    return null;
  }

  const getCustomerMessageCounter = (customerId) => {
    let messagesCustomer = [];
    messages.forEach((messageItem)=>{
      const messageExist = ((((messageItem.author==='support')&&(customerId===messageItem.to))||(messageItem.author===customerId))||((messageItem.author!=='support') && customerId===messageItem.author)) ? messageItem : null 
      if(messageExist) {
        messagesCustomer.push(messageItem);
      }
    })
    return messagesCustomer.length;
  }

  const handleSoundRing = (e) => {
    setSoundRing(!soundRing);
  }

  function enableLoop() { /*w  w w. j a v  a 2s . c  o  m*/
    audio.loop = false;
    audio.load();
    audio.play();
  } 

  function disableLoop() { 
    audio.loop = false;
    audio.load();
  } 

  const playSoundRing = () => {
    const exist = customersList.find((item)=>item.read==='unread');
    if(exist!==undefined) {
      if(soundRing===true) {
        enableLoop();
      }
    } else {
      disableLoop();
    }
  }

  return (
    <div className='move-top admin-wrapper'>
      <div className='messages-side'>
        <div className='messages-header'>
          <H5>Meetings</H5>
          <div className='sound-wrapper'>
            Sonido <input type='checkbox' checked={soundRing} onClick={(e)=>handleSoundRing(e)} />
          </div>
          {playSoundRing()}
        </div>
        <div className='customers-items' style={{maxHeight: `${chatWrapperHeight+64}px`}}>
          {customersList.length> 0 ? (
            customersList.map((item, index) => {
              return (
                <div key={index}>
                  <div className={`customer-item ${item.to===customerId ? 'selected': ''}`} onClick={()=>chatSelected(item.to)}>
                    <div className='avatar'><div className={`avatar-bg icon-avatar ${JSLib.getColor(index)}`}></div></div>
                    {/*<div className='content'>{JSLib.getTokenToCustomer(item.to)} {(item.read==='unread') ? circleNewMessage(item) : null}</div>*/}
                    <div className='content'>
                      <div className='item'>{item.chat_data ? renderCustomerList(item) : JSLib.getTokenToCustomer(item.to)} {(item.read==='unread') ? circleNewMessage(item) : null}</div>
                      <div className='item text-small'>{item.chat_location ? renderCustomerLocation(item) : ''}</div>
                      <div className='item text-small time'>{item.chat_location ? renderCustomerDate(item) : ''}</div>
                      {item.chat_location ? renderCustomerMessageCounter(item) : ''}
                    </div>
                  </div>
                </div>);
              }
            )): (
              <div className='messages-empty'>
                <Section>
                  <Row>
                    <Col><H5>Aún no has recibido llamadas...</H5></Col>
                  </Row>
                  <Row>
                    <Col>
                      {session.custom.channels.map((item)=>{
                        const rapimeet_link = `https://rapimeet.com/${item.channel_linkname}`;
                        return (<div>
                          <div className='header'>Comparte tu enlace Rapimeet en tus redes sociales, sitio web o donde quieras...</div>
                          <div className='align-right'><button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(rapimeet_link)}>Copiar</button> </div>
                          <input className='inp' value={`https://rapimeet.com/${item.channel_linkname}`}/>
                        </div>)
                      })}                    
                    </Col>
                  </Row>
                </Section>
              </div>
            )
          }
        </div>
      </div>
      {showChat ? (<div className='chat-mobile-background' onClick={()=>setShowChat(!showChat)}></div>) : null}
      <div ref={chatWrapper} className={`chat-wrapper ${showChat ? 'show': ''}`}>
      {/*<div className='chat-wrapper'>*/}
        <div className='content'>
          <div ref={chatWrapper} className='content-messages'>
          {getCustomerMessageCounter(customerId)>0 ? (
            <div className='messages-box '>
              <div id='messages' className='messages' style={{maxHeight: `100vh`}}>                
                <div className='messages-items'>
                  {messages.map((item, index) => {
                    const messageItem = ((((item.author==='support')&&(customerId===item.to))||(item.author===customerId))||((item.author!=='support') && customerId===item.author)) ? item : null 
                    // if(item.author===customerId){
                    if(messageItem) {
                      return (
                        <div key={index} className={(item.author===config.from) ? 'message-item me' : 'message-item'}>
                          <div className='avatar avatar-bg'>
                            <div className='icon-avatar'></div>
                          </div>
                          <div className='bubble'>
                            <div className='author'>{JSLib.getTokenToCustomer(item.author)}</div>
                            <div className='message'>{JSLib.convertToLinks(item.message)}</div>
                            {item.component ? item.component : null}
                            <div className='time'>{item.created_at_localtime}</div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          ): (
            <div className='messages-empty'>
              <div className='messages'>
                <div className='calling-box'>
                  <div class="calling-wrapper">
                    <div className='icon-status-stop'></div>
                    <div className='icon-calling-bg-disabled'></div>
                  </div>
                  <div>
                    <H5>Ups! No hay mensajes</H5>
                    <div className='customer'>Cliente: {customerId}</div>
                  </div>
                </div>
                
              </div>
            </div>
          )}
            {/*
            <div className='bottom'>
              <div className='message-input-wrapper'>
                <textarea className='message-input' name='message' value={formData.message} placeholder={'Mensaje'} onChange={handleInputChange} onKeyDown={handleKeyDown}></textarea>
                <button className='btn message-send' onClick={()=>handleSendMessage()}>
                  <div className='icon-send'></div>
                </button>
              </div>
            </div>
            */}

          </div>

        </div>
      </div>
    </div>
  );
}

export default Admin;