import React, { useEffect, useState, useRef } from 'react';
import VoiceCall from '../../../components/voiceCall/VoiceCall';
import './Room.css';
import * as JSLib from '../../../lib/Lib';
import { JS, Modal } from 'grovefy-ui-pack';
import PaymentCustomerModal from '../../../components/paymentCustomerModal/PaymentCustomerModal';
import { request, ContentTypes } from '../../../store/api/api';

/* eslint-disable */
declare var grovefy: any; // Declare global realtime communication

function Room() {
  const [formData, setFormData] = useState({});
  // const [localSocketID, setLocalSocketID] = useState();
  const [grovefyInitialized, setGrovefyInitialized] = useState(false);
  const [channel, setChannel] = useState();
  const messagesCookie = JSLib.getMessagesCookie();
  const [messages, setMessages] = useState(messagesCookie);
  // const [callReceived, setCallReceived] = useState({});
  const chatWrapper = useRef();
  const [chatWrapperHeight, setChatWrapperHeight] = useState();
  const [showChat, setShowChat] = useState(false);
  const [componentModal, setComponentModal] = useState(null);
  const [propsVoiceCall, setPropsVoiceCall] = useState(null);
  const [newMessageNotification, setNewMessageNotification] = useState(false);

  const getCustomerCookie = () => {
    const grovefy_customer_id = grovefy.getCookie('from') ? grovefy.getCookie('from') : grovefy.getCookie('grovefy_customer_id');
    /*if(!grovefy_customer_id) {
      grovefy.setCookie('grovefy_customer_id', 'customer_'+JSLib.createToken());
    }*/
    return grovefy_customer_id;
  }

  const config = {
    group: JSLib.getURLParam('group'),
    from: JSLib.getURLParam('from'),
    to: JSLib.getURLParam('to'), // ['superadmin'] / ['info@example.com']  / ['123456'] / ['tablet', 'iphone']
    data: {
      function: 'initializeConnection',
      parameters: {}
    }
  }

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  useEffect(() => {
    if(!channel) {
      getEndpointChannel();
    }
    if(grovefyInitialized===false) {
      grovefy.init(config);
      setTimeout(() => {
        const data = {
          function: 'initialize',
          parameters: {
            grovefy_customer_id: getCustomerCookie()
          }
        };
        grovefy.send(data);
        getChatResize();
      }, 2000);
      setGrovefyInitialized(true);
      checkPayment();
    }
    if(chatWrapperHeight===undefined) {
      getChatResize();
    }
    window.addEventListener("resize", getChatResize);
  }, []);

  useEffect(()=> {
  }, [messages]);

  const handleSendMessage = () => {
    const data = {
      function: 'chat',
      parameters: { 
        'message': formData.message
      }
    };
    grovefy.send(data);
    // Message screen
    const new_message = {
      to: JSLib.getURLParam('to'),
      author: config.from,
      message: formData.message
    };
    const data_message = {
      author: new_message.author,
      to: new_message.to,
      message: new_message.message
    };
    setEndpointMessage(data_message);
    addMessage(new_message);
  }

  const addMessage = (new_message) => {
    //new_message.created_at = Date.now();//JSLib.getTime();
    new_message.created_at = JSLib.getTimestampNow();
    new_message.created_at_localtime = JSLib.timestampToDate(new_message.created_at);
    const messages_updated = messages.concat(new_message);
    setMessages(messages_updated);
    JSLib.setMessagesCookie(new_message);
    // Move to bottom of .messages
    setTimeout(() => {
      const elem = document.querySelector(`.messages`);
      elem.scrollTo(0, elem.scrollHeight);
      if(showChat===false) {
        setNewMessageNotification(true);
      }
    }, 200);
  
  }

  const setEndpointMessage = async (message) => {
    const payload = {
      "channel_id": channel.channel_id,
      "message_author": message.author,
      "message_to": message.to,
      "message_content": message.message
    };
    const endpoint =`/message/create`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const data = res.data.data;
    } catch (error) {
      console.log(error);
    }
  }
  // Get branch related to the meet
  async function getEndpointChannel() {
    const channel_linkname = JSLib.getURLParam('group');
    const data = {
      "channel_linkname" : channel_linkname
    };
    const endpoint = `/channel`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data.data;
      setChannel(result);
    } catch (err) {
      console.log(err)
    }
  };

  const chat = (from, data_received) => {
    const new_message = {
      author: from,
      message: data_received.message
    };
    if(from===config.to) {
      addMessage(new_message);
    }
  }

  const paymentCustomerReceived = (from, data_received) => {
    if(from===config.to) {
      JSLib.grovefyClear();
      openPaymentCustomerModal(data_received);
    }
  }

  grovefy.receive = (data_received) => {
    const { group, from, to, data } = data_received;
    if(from===config.to) {
      switch(data.function) {
        case 'chat':{
          chat(from, data.parameters);
          break;
        }
        case 'callInReceived': {
          setPropsVoiceCall(data_received);
          break;
        }
        case 'paymentCustomer': {
          paymentCustomerReceived(from, data.parameters);
          break;
        }
        case 'openRequestPayment': {
          openRequestPayment(from, data.parameters);
          break;
        }
        default: {
          // console.log('Default: data: ', data);
        }
      }      
    }

  }

  function openPaymentCustomerModal(data) {
    setComponentModal(<PaymentCustomerModal close={()=>closeModal()} save={()=>saveModal()} data={data}/>);
  }

  function saveModal() {
    //setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
      setTimeout(() => {
        setFormData({
          ...formData,
          ['message']: ''
        })        
      }, 100);
    }
  }

  const getChatResize = () => {
    const chatHeight = chatWrapper?.current.offsetHeight;
    setChatWrapperHeight(chatHeight-280);
    setTimeout(() => {
      const elem = document.querySelector(".messages");
      elem.scrollTo(0, elem.scrollHeight);
      elem.scrollIntoView();
    }, 200);
    /*if(chatWrapper?.current.offsetWidth<(chatWrapper?.current.offsetHeight*1.77)) {
      setChatWrapperHeight(videoWrapper?.current.offsetHeight);
    } else {
      //setVideoWrapperWidth(videoWrapper?.current.offsetHeight*1.77);
      setChatWrapperHeight(chatWrapper?.current.offsetHeight);      
    }*/
  }

  const handleCloseMenu = () => {
    setShowMenu(false);
  }

  const checkPayment = () => {
    const pi_encoded = JSLib.getURLParam('pi');
    if((pi_encoded!==null) && (grovefy.getCookie('pi')==='')) {
      const pi = JS.decodeString(pi_encoded);
      grovefy.setCookie('pi', pi);http://localhost:8081/room?group=support&from=customer_test12@gmail.com&to=support&call=share-screen
      setTimeout(() => {
        const data = {
          function: 'openRequestPayment',
          parameters: {
            pi: pi
          }
        };
        grovefy.send(data);
      }, 2000);
    }
  }

  const messagePaymentButtons = async (data_received) => {
    const { pi } = data_received;

    const payload = {
      "pi" : pi.toString()
    };
    const endpoint = `/payment_stripe/pi`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const result = res.data;
      const { amount, currency, metadata, created } = result.data;
      const amount_decimal = JSLib.stripeAmountToDecimal(amount);
      const currency_converted = JSLib.stripeCurrency(currency);
      const created_converted = JSLib.timestampToDate(created);
      return (
        <div className='message-payment-ticket'>
          <div>Tax ID: {pi}</div>
          <div>Email: {metadata.payment_to_email}</div>
          <div>Monto: <b>{currency_converted}{amount_decimal}</b></div>
          <div>Fecha: {created_converted}</div>
          <div>Para visualizar todos los pagos debe ir a Home > Ingresos</div>
        </div>
      )
    } catch (err) {
      console.log(err)
      return <div>Error</div>;
    }
  }
/*
  const getPaymentIPData = async (pi) => {
    const payload = {
      "pi" : pi.toString()
    };
    const endpoint = `/payment/pi`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('getPaymentIPData: result', result);
      return result;
    } catch (err) {
      console.log(err)
      return null;
    }
  }

  const messagePaymentButtons = (data_received) => {
    const { pi } = data_received;
    const response = getPaymentIPData(pi);
    console.log('messagePaymentButtons: response:', response);
    const data = {
      pi: data_received.pi,
    };

    return (
      <div>
        <div>PI: {data.pi}</div>
        <div>Email: {data.pi}</div>
        <div>Monto: {response.amount}</div>
        <div>Fecha: {data.pi}</div>
      </div>
    )
  }
*/
  const openRequestPayment = async (from, data_received) => {
    const new_message = {
      to: from,
      author: from,
      message: 'Pago recibido...',
      component: await messagePaymentButtons(data_received)
    };
    addMessage(new_message);
    // Show Unread
  }

  const handleShowChat = () => {
    setShowChat(!showChat);
    setNewMessageNotification(false);
    setTimeout(() => {
      getChatResize();
    }, 1000);
  }

  return (
    <div className='room-wrapper'>

      <div className='call-wrapper'>
        <div className='room-header'>
          <a href={`https://rapimeet.com/${JSLib.getURLParam('group')}`} className='logo-link'>
            <div className='customer-logo'></div>/{JSLib.getURLParam('group')}
          </a>
        </div>
        <div className='notification-bottom'>Para llamadas de voz o video, conecte su dispositivo a su red WIFI.</div>
        <VoiceCall config={config} propsVoiceCall={propsVoiceCall}/>
      </div>
      
      {showChat ? (<div className='chat-mobile-background' onClick={()=>{handleShowChat()}}></div>) : null}

      <div ref={chatWrapper} className={`chat-wrapper ${showChat ? 'show': ''}`}>
        {/*<header>Atención al cliente</header>*/}
        <div className='content'>
          <div  className='messages-box'>
            <div id='messages' className='messages' style={{maxHeight: `${chatWrapperHeight}px`, position: 'relative', top: 0}}>
              <div  className='messages-items'>
                {messages.map((item, index) => {
                  if((((item.author==='support')&&(JSLib.getURLParam('to')===item.to))||(item.author===JSLib.getURLParam('to')))||((item.author!=='support') && JSLib.getURLParam('from')===item.author)) {
                    return (
                      <div key={index} className={(item.author===config.from) ? 'message-item me' : 'message-item'}>
                        <div className='avatar avatar-bg'>
                          <div className='icon-avatar'></div>
                        </div>
                        <div className='bubble'>
                          <div className='author'>{JSLib.getTokenToCustomer(item.author)}</div>
                          <div className='message'>{JSLib.convertToLinks(item.message)}</div>
                          {item.component ? item.component : null}
                          <div className='time'>{item.created_at_localtime}</div>
                        </div>
                      </div>
                    )                    
                  }
                })}
              </div>
            </div>

          </div>

        </div>
        <div className='bottom'>

          <div className='message-input-wrapper'>
            <textarea className='message-input' name='message' value={formData.message} placeholder={'Mensaje'} onChange={handleInputChange} onKeyDown={handleKeyDown}></textarea>
            <button className='btn message-send' onClick={()=>handleSendMessage()}>
              <div className='icon-send'></div>
            </button>
          </div>

        </div>
        <footer>
          <div className='room-footer'>© 2024 <a href='https://rapimeet.com' target='_blank'>Rapimeet</a>, Todos los derechos reservados</div> 
        </footer>
      </div>

      <div className='chat-mobile-bubble' onClick={()=>handleShowChat()}>
        {newMessageNotification ===true? <div className='new-message-circle'></div> : null}
      </div>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default Room;