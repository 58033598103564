import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setItems } from '../../../store/items/ItemsActions';
// import { getStatus } from '../../../store/api/api';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Input, H3, Notification, JS, Button } from 'grovefy-ui-pack';
import { PREFIX_URL } from '../../../store/constants';
import './CreateChannel.css';

function CreateChannel(props) {
  const session = JS.getSession();
  const [formData, setFormData] = useState({
    channel_linkname: '',
    channel_name: '',
    channel_description: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function getEndpointChannel(channel_linkname) {
    const data = {
      "channel_linkname" : channel_linkname
    };
    const endpoint = `/channel`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const channelResult = res.data;
      if(channelResult.result==='false') {
        createAccount();
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: `El nombre "${channel_linkname}" no esta disponible.`
        });
      }
    } catch (err) {
      console.log(err)
    }
  };

  async function createAccount() {
    if((formData.channel_linkname==='') ||
      (formData.channel_name==='') ||
      (formData.channe_description==='')) {
      setNotification({
        status: true,
        className: 'danger',
        message: 'Debe completar todos los campos.'
      });
      setLoading(false);
    } else {
      setNotification({
        status: false,
        className: '',
        message: ''
      });
      
      const payload = {
        "channel_linkname": formData.channel_linkname.toLowerCase(),
        "channel_name": formData.channel_name,
        "channel_description": formData.channel_description,
        "channel_admin_account_id": session.account.account_id,
        "calendar_status": false
      };

      const endpoint = `/channel/create`;
      try {
        const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
        const { result } = res.data;

        if (result) {
          // setAccountCreated(true);
          const channels = [{channel_linkname: payload.channel_linkname}]
          const custom = {
            channels
          }
          JS.updateSession({custom});
          localStorage.setItem('new',true);
          endpointRefreshLogin();
          // loginAccount({email: formData.email, password: formData.password});
          
        }
      } catch (err) {
        console.log(err)
        setNotification({
          status: true,
          className: 'danger',
          message: 'Error al ingresar los datos.'
        });
        setLoading(false);
      }
      
    }
  }

  async function endpointRefreshLogin() {
    // event.preventDefault();
    const session = JS.getSession();
    const {account_email, account_firstname, account_lastname, account_tier} = session.account;
    const data = {
      "account_email": account_email,
    };

    const endpoint = `/account/reload`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data.data;

      if (user && user.account_id) {
        JS.setSession({ user });
        const custom = {
          channels: user.channels
        }
        JS.updateSession({custom});
        window.location.href = `./paymentTier`;
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Usuario o contraseña incorrecto'
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getEndpointChannel(formData.channel_linkname);
  }

  return (
    <div>
      <Section maxWidth='small' className='box-center'>
        <Row>
          <Col><H3>Crear perfil Rapimeet</H3></Col>
        </Row>
        <form onSubmit={ e =>handleSubmit(e)}>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <div className='input-linkname'>
                <div className='label'>https://rapimeet.com/</div>
                <div className='input-wrapper'><Input type='text' name='channel_linkname' value={formData.channel_linkname} placeholder='Linkname' onChange={handleInputChange}/></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='text' name='channel_name' value={formData.channel_name} placeholder='Nombre de meet' onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea type='text' name='channel_description' value={formData.channel_description} placeholder='Descripción' onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <button type='submit' className='btn primary'>Crear perfil</button>
            </Col>
          </Row>
        </form>
      </Section>
    </div>
  );
}

export default CreateChannel;