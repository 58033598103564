import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'grovefy-ui-pack';
import axios from "axios";
import { tierPrices, stripeAmountToDecimal } from '../../../../lib/Lib';

function PaymentGen() {
  const prices = tierPrices();
  const account_tier = 'starter';
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    amount_stripe: '',
    product_name: 'Starter',
    account_tier
  });

  useEffect(()=>{
  })

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log('handleSubmit: formData: ', formData);
    const dataPayment = JS.encodeString(JSON.stringify(formData));
    const url = 'https://rapimeet.com/paymentCustomer?dataPayment='+dataPayment;
    console.log('url: ', url);
    sendEmail(url);
  }

  async function sendEmail(url) {
    const payload = {
      "to": formData.email.toLowerCase(),
      "subject": "Servicios Rapimeet",
      "message": "<h2>¡Pagos Rapimeet!</h2>"+
      "<p>¡Hola "+formData.firstname+"!</p>"+
      "<p>Te enviamos el enlace para realizar el pago del mantenimiento de servicio "+formData.product_name+" €"+formData.amount_stripe+".</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<a style='background-color: #0057FF; color: #FFFFFF; font-weight: 600; border-radius: 30px; padding: 10px 40px; text-decoration: none;' href='"+url+"'>Pagar ahora</a></div>"
    };

    const endpoint = `/sendHTML`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      if(result) {
        window.location.href = `/login`;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='payment-wrapper'>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <H3>Payment Gen</H3>
          </Col>
        </Row>
        <form onSubmit={(e) =>handleSubmit(e)}>
          <Row>
            <Col>
              <Input type='firstname' name='firstname' value={formData.firstname} placeholder={'Nombre'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='lastname' name='lastname' value={formData.lastname} placeholder={'Apellido'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='email' name='email' value={formData.email} placeholder={'Email'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <div>
            <Row>
              <Col>
                <select className='inp' name='account_tier' onChange={null}>
                  {prices.map((item) => {
                    return (<option value={item.tier_id} selected={account_tier===item.tier_id ? true : false}>{item.tier_name} (€{stripeAmountToDecimal(item.tier_price)} mes)</option>)
                  })
                  }
                </select>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <Input type='text' name='product_name' value={formData.product_name} placeholder={'Starter'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='text' name='amount_stripe' value={formData.amount_stripe} placeholder={'Amount Stripe (ex: 2000 for 20,00)'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <button className="btn primary">Enviar solicitud</button>
            </Col>
          </Row>
        </form>
      </Section>
    </div>
  );
}

export default PaymentGen;