import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, H4, H5, Notification, Button, JS, Footer } from 'grovefy-ui-pack';
import * as JSLib from '../../../lib/Lib';

function ScheduleConfirm(props) {
  const { scheduleSelected, channel } = props;
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if((formData.firstname==='') ||
    (formData.lastname==='') ||
    (formData.email==='')) {
      setNotification({
        status: true,
        className: 'danger',
        message: 'Debe completar todos los campos.'
      });
    } else {
      setEndpointScheduleCreate();
    }
  }

  const setEndpointScheduleCreate = async () => {
    setLoading(true);
    const { channel_id } = channel;
    const {schedule_date, schedule_time_start, schedule_time_end } = scheduleSelected;
    const customer_pin = Math.floor(1000 + Math.random() * 9000);
    const payload = {
        "channel_id": channel_id,
        "schedule_date": schedule_date,
        "schedule_time_start": schedule_time_start,
        "schedule_time_end": schedule_time_end,
        "customer_email": formData.email.toLowerCase(),
        "customer_data": {
          "customer_firstname": formData.firstname,
          "customer_lastname": formData.lastname,
          "customer_pin": customer_pin
        }
    };
    const endpoint = `/schedule/create`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setLoading(false);
      if (result) {
        // setAccountCreated(true);
        //loginAccount({email: formData.email, password: formData.password});
        sendEmail(payload);
        setFinished(true);
      }
    } catch (err) {
      console.log(err)
      setNotification({
        status: true,
        className: 'danger',
        message: 'Error al ingresar los datos.'
      });
      setLoading(false);
    }
  }

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function sendEmail(data) {
    const to = JSLib.encodeToken(JSON.stringify({
      email: data.customer_email,
      fullname: `${data.customer_data.customer_firstname} ${data.customer_data.customer_lastname}`,
      time_session: `${data.schedule_date} ${data.schedule_time_start} ${data.schedule_time_end}`,
    }));
    const { channel_linkname } = channel;
    const link = `${JSLib.getHost()}/recall/${channel_linkname}/${to}`;
    const {schedule_date, schedule_time_start, schedule_time_end } = scheduleSelected;
    // const schedule_time = `${schedule_date} de ${schedule_time_start} a ${schedule_time_end}`;
    const schedule_time = JSLib.convertTime(`${schedule_date} ${schedule_time_start} ${schedule_time_end}`);
    const payload = {
      "to": formData.email.toLowerCase(),
      "subject": "Reunión confirmada",
      "message": "<h2>¡Bienvenido a Rapimeet!</h2>"+
      "<p>¡Hola "+formData.firstname+"!</p>"+
      "<p>Has agendado una reunión."+
      "<p>Fecha de reunión: <b>"+schedule_time+"</b></p>"+
      "<p>Llamada a: <b>"+channel_linkname+"</b></p>"+
      "<p>Enlace de reunión: <b>"+link+"</b></p>"+
      "<p>Importante: Para acceder a la reunión ingrese al enlace de reunión y haga click en el botón <b>Llamar ahora</b>.</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<a style='background-color: #0057FF; color: #FFFFFF; font-weight: 600; border-radius: 30px; padding: 10px 40px; text-decoration: none;' href='"+link+"'>Ir a Rapimeet</a></div>"
    };

    const endpoint = `/sendHTML`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      sendAdminChannel(data);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  async function sendAdminChannel(dataCustomer) {
    const data = {
      "account_id" : channel.channel_admin_account_id
    };
    const endpoint = `/account`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const accountData = res.data.data;
      sendEmailAdminChannel(dataCustomer, accountData.account_email);
    } catch (err) {
      console.log(err)
    }
  };

  async function sendEmailAdminChannel(data, admin_email) {
    const to = JSLib.encodeToken(JSON.stringify({
      email: admin_email,
      fullname: `${data.customer_data.customer_firstname} ${data.customer_data.customer_lastname}`,
      time_session: `${data.schedule_date} ${data.schedule_time_start} ${data.schedule_time_end}`,
    }));
    const { channel_linkname } = channel;
    const link = `${JSLib.getHost()}/recall/${channel_linkname}/${to}`;
    const {schedule_date, schedule_time_start, schedule_time_end } = scheduleSelected;
    // const schedule_time = `${schedule_date} de ${schedule_time_start} a ${schedule_time_end}`;
    const schedule_time = JSLib.convertTime(`${schedule_date} ${schedule_time_start} ${schedule_time_end}`);
    const customer_fullname = formData.firstname+" "+formData.lastname;
    const payload = {
      "to": admin_email,
      "subject": "Reunión confirmada",
      "message": "<h2>Reunión agendada</h2>"+
      "<p>Se ha agendado una reunión."+
      "<p>Cliente: <b>"+formData.firstname+" "+formData.lastname+"</b></p>"+
      "<p>Email de cliente: <b>"+formData.email.toLowerCase()+"</b></p>"+
      "<p>Fecha de reunión: <b>"+schedule_time+"</b></p>"+
      "<p>Importante: Para acceder a la reunión ingrese a https://rapimeet.com con su cuenta y espere a recibir la llamada que figura con el nombre <b>"+customer_fullname+"</b> en la columna <b>Meetings</b>.</p>"+
      "<div style='margin-top: 40px; margin-bottom: 40px'>"+
      "<a style='background-color: #0057FF; color: #FFFFFF; font-weight: 600; border-radius: 30px; padding: 10px 40px; text-decoration: none;' href='https://rapimeet.com'>Ir a Rapimeet</a></div>"
    };

    const endpoint = `/sendHTML`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className='meet-wrapper'>
      <div className='meet-header'>
        <div className='customer-logo'></div>
      </div>
      <div className='meet-container'>
        <Section maxWidth='small' className='box-center'>
          {finished===false ? (
            <div className='welcome-wrapper'>
              <Row>
                <Col>
                  <H4>Finalizar reserva</H4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Notification className='alert'>Completa los datos para confirmar tu reserva.</Notification>
                </Col>
              </Row>
              <form onSubmit={(e)=>handleSubmit(e)}>
                <Row>
                  <Col>
                    Fecha/Hora:
                    <H5>{JSLib.convertTime(`${scheduleSelected.schedule_date} ${scheduleSelected.schedule_time_start} ${scheduleSelected.schedule_time_end}`)}</H5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input className='inp' type='text' name='firstname' placeholder='Nombre' value={formData.firstname} onChange={handleInputChange} required />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input className='inp' name='lastname' type='text' placeholder='Apellido' value={formData.lastname} onChange={handleInputChange} required/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <input className='inp' name='email' type='email' placeholder='Email' value={formData.email} onChange={handleInputChange} required/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type='submit' className='primary' label='Confirmar reserva' action={()=>null}/>
                  </Col>
                </Row>
              </form>
            </div>
          ): (
            <div className='welcome-wrapper'>
              <Row>
                <Col>
                  <H4>Reserva confirmada</H4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Notification className='success'>Hemos enviado un email con el enlace a la reunión, en caso de no poder participar solicita su cancelación.</Notification>
                </Col>
              </Row>
              <Row>
                <Col>
                  Fecha/Hora:
                  <H5>{JSLib.convertTime(`${scheduleSelected.schedule_date} ${scheduleSelected.schedule_time_start} ${scheduleSelected.schedule_time_end}`)}</H5>
                </Col>
              </Row>
              <Row>
                <Col>Nombre y apellido: <H5>{`${formData.firstname} ${formData.lastname}`}</H5></Col>
              </Row>
              <Row>
                <Col>Email: <H5>{formData.email}</H5></Col>
              </Row>
            </div>
          )}
        </Section>
      </div>
      <Footer
        companyName='Rapimeet, Todos los derechos reservados'
        linkPrivacy='./privacy'
        linkTerms='./terms'
      ></Footer>
    </div>
  )
}
export default ScheduleConfirm;