import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Section, Row, Col, H3, Table, Thead, Tr, Th, Tbody, Td, JS } from 'grovefy-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { amountToStripeFormat, stripeAmountToDecimal } from '../../../../lib/Lib';

function PromotionalCode() {
  const { promotional_code_code } = useParams();
  const promotional_code = JS.decodeString(promotional_code_code);
  const [promotionalCodeList, setPromotionalCodeList] = useState(); 
  
  useEffect(()=>{
    console.log('promotionalCodeList:', promotionalCodeList);
    if(!promotionalCodeList) {
      console.log('promotionalCodeList: ',promotionalCodeList);
      getEndpointPromotionalCodeList(promotional_code);
    }
  })

  async function getEndpointPromotionalCodeList(promotional_code) {
    const data = {
      "promotional_code_code": promotional_code
    };
    const endpoint = `/promotional_code`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const resultPromotionalCodeList = res.data.data;
      setPromotionalCodeList(resultPromotionalCodeList);
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div>
      <Section>
        <Row>
          <Col>
            <H3>Mis ingresos</H3>
          </Col>
        </Row>
        <Row>
          <Col>
            Código Promocional: {promotional_code}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='table-wrapper'>
              <div className='table-scroll'>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Email</Th><Th>Fecha</Th><Th>Monto</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {promotionalCodeList ? promotionalCodeList.map((item, index)=>{
                      const {account_email, created_at, promotional_code_currency, promotional_code_amount} = item;
                      const amount = stripeAmountToDecimal(promotional_code_amount);
                      return (
                        <Tr key={index}>
                          <Td>{account_email}</Td><Td>{created_at}</Td><Td>{promotional_code_currency} {amount}</Td>
                        </Tr>
                      )
                    }) : null}
                  </Tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default PromotionalCode;