import React from 'react';
import { Section, Row, Col, Loading, JS } from 'grovefy-ui-pack';
import './Close.css';
import { PREFIX_URL } from '../../../store/constants';
/*import mixpanel from 'mixpanel-browser';

mixpanel.init(MIXPANEL_TOKEN, {debug: false});
*/
function Sessions() {
  const session = JS.getSession();

  // const iframe_preload_play = `<iframe src='https://${PREFIX_URL}play.grovefy.com/session/reset.html' />`;
  const session_token = '';

  // Save Cookie to local machine
  JS.setCookie({cname:'session_token', cvalue: session_token});
  // Store sessionStorage for access to iframes from externals domains
  sessionStorage.setItem('session_token', session_token);
  window.location.href = `./`;
/*
  setTimeout(function () {
    window.location.href = `https://${PREFIX_URL}account.grovefy.com`;
  }, 3000);*/

  return (
    <div>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <Loading visible={true} className='center'/>
          </Col>
        </Row>
      </Section>
      {/*<div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_payment+" "}} />
      <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_play+" "}} />*/}
    </div>
  );
}

export default Sessions;