import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, H5, Notification, JS } from 'grovefy-ui-pack';
import { PREFIX_URL } from '../../../store/constants';

function Jobs() {
  const {t} = useTranslation('common');

  return (
    <div className='medium'>
      <Section maxWidth='medium'>
        <Row>
          <Col><H3>Empleos</H3></Col>
        </Row>
        <Row>
          <Col>
            <Link to={'/jobs/devops/29231'}>DevOps Engineer - New York, New York, US</Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to={'/jobs/analyst/37516'}>SEC Reporting and External Audit Analyst - New York, New York, US</Link>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Jobs;