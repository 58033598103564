import React, { useEffect, useState } from 'react';
import { Section, Row, Col, H3, Tabs, Tab } from 'grovefy-ui-pack';
import Schedule from './tabs/schedule/Schedule';
import EditSchedule from './tabs/editSchedule/EditSchedule';

function Calendar() {

  return (
    <div>
      <Section>
        <H3>Calendario</H3>
      </Section>
      <Section>
        <Row>
          <Col>
            <Tabs>
              <Tab data='Próximas meetings'>
                <Schedule/>
              </Tab>
              <Tab data='Editar disponibildad'>
                <EditSchedule/>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Section>
    </div>
  )
}

export default Calendar;