import React from 'react';
import { Header, Container, Footer } from 'grovefy-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';

function PublicLayout(props: any) {
  const menu = () => {
    return (
      <ul>
        <li><a href='/'>Home</a></li>
        <li><a href='/prices'>Precios</a></li>
        <li><a href='/jobs'>Empleo</a></li>
      </ul>
    )
  }

  return (
    <div className="public-layout bg-public">
      <Header sessionLinks={sessionLinks}>{/*menu()*/}</Header>
      <Container>
        {props.children}
      </Container>
      <Footer
        companyName='Rapimeet, Todos los derechos reservados'
        linkPrivacy='./privacy'
        linkTerms='./terms'
      >{menu()}</Footer>
    </div>
  );
}

export default PublicLayout