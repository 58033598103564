import React, {useState, useEffect} from 'react';
import { Row, Col, Notification, Input, H5, Button, JS } from 'grovefy-ui-pack';
import './PaymentCustomerModal.css';
import * as JSLib from '../../lib/Lib';
import { request, ContentTypes } from '../../store/api/api';

const PaymentCustomerModal = (props: any) => {
  const { close, data } = props;
  const { amount, currency, payment_to_channel, payment_to_email, payment_to_fullname, payment_channel_id } = data;
  const channel_id = payment_channel_id;
  const [paymentProvider, setPaymentProvider] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    payment_to_channel,
    payment_to_email,
    payment_to_fullname,
    amount,
    currency
  });

  useEffect(()=>{
    if(loading===false) {
      getEndpointPaymentProvider(channel_id);
    }
  });

  const getEndpointPaymentProvider = async (channel_id) => {
    const payload = {
      channel_id
    };
    const endpoint = `/payment_provider`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const paymentProviderResult = res.data.data;
      setPaymentProvider(paymentProviderResult);
      setLoading(true);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const saveModal = () => {
    /*
      Include url to return after the payment
      Encode referer URL and payment data
     
      Example:
      urlRefererDecoded: http://localhost:8081/room?group=testa3&from=customer_1686254354598&to=support&call=share-screen
    
      Example:
      formData = {
        "payment_to_channel":"testa3",
        "payment_to_email":"testa3@gmail.com",
        "payment_to_fullname":"Test a3 Test a3",
        "amount":10,
        "currency":"EUR"
      }
    */
    // Encode
    const urlReferer = JS.encodeString(window.location.href);
    const dataPayment = JS.encodeString(JSON.stringify(formData));
    const refererEncode= 'urlReferer='+urlReferer+'&dataPayment='+dataPayment;

    // Decode
    const urlParams = new URLSearchParams(refererEncode);
    const urlRefererDecoded = JS.decodeString(urlParams.get('urlReferer'));
    const dataPaymentDecoded = JS.decodeString(urlParams.get('dataPayment'));
    window.location.href='./payment?'+refererEncode;
    // save(formData);
  }

  const closeModal = () => {
    close();
  }

  const renderProviderBizum = () => {
    const { bizum_phone } = selectedProvider.payment_provider_data;
    return (
      <Row>
        <Col>
          <Row><Col><H5>Pago por Bizum</H5></Col></Row>
          <Row>
            <Col>
              Realiza el pago al siguiente número de móvil
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Monto:
              <div className='payment-amount'>{JSLib.currencyToSymbol(formData.currency)} {formData.amount}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='input-copy'>
                +34 
                <Input className='small' value={bizum_phone}/>
                <button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(bizum_phone)}></button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const renderProviderBankAccount = () => {
    const { bank_account_fullname, bank_account_name, bank_account_number, bank_account_swift } = selectedProvider.payment_provider_data;
    return (
      <Row>
        <Col>
          <Row><Col><H5>Pago por Cuenta Bancaria</H5></Col></Row>
          <Row className='mb-column'>
            <Col className='label'>
              Monto:
              <div className='payment-amount'>{JSLib.currencyToSymbol(formData.currency)} {formData.amount}</div>
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Nombre del destinatario
              <div className='input-copy'>
                <Input className='small' value={bank_account_fullname}/>
                <button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(bank_account_fullname)}></button>
              </div>
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Nombre de banco
              <div className='input-copy'>
                <Input className='small' value={bank_account_name}/> <button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(bank_account_name)}></button>
              </div>
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Cuenta bancaria
              <div className='input-copy'>
                <Input className='small' value={bank_account_number}/>
                <button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(bank_account_number)}></button>
              </div>
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              SWIFT/BIC
              <div className='input-copy'>
                <Input className='small' value={bank_account_swift}/>
                <button className='btn-clipboard' onClick={() => navigator.clipboard.writeText(bank_account_swift)}></button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
/*
  if(selectedProvider==='bizum') {
    return (
      <div className='w-100'>
        <div className='modal-header'>Pagar servicio</div>
        <div className='modal-content'>
          <Row className='mb-column'>
            <Col className='label'>
              Destino: https://rapimeet.com/{formData.payment_to_channel}
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Propietario: {formData.payment_to_email}
              <div className='text-small'>({formData.payment_to_fullname})</div>
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Monto a abonar:
              <div className='payment-amount'>{JSLib.currencyToSymbol(formData.currency)} {formData.amount}</div>
            </Col>
          </Row>
          {renderProviderBizum()}
        </div>
      </div>
    )
  }

  if(selectProvider==='bank_account') {
    return (
      <div className='w-100'>
        <div className='modal-header'>Pagar servicio</div>
        <div className='modal-content'>
          <Row className='mb-column'>
            <Col className='label'>
              Destino: https://rapimeet.com/{formData.payment_to_channel}
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Propietario: {formData.payment_to_email}
              <div className='text-small'>({formData.payment_to_fullname})</div>
            </Col>
          </Row>
          <Row className='mb-column'>
            <Col className='label'>
              Monto a abonar:
              <div className='payment-amount'>{JSLib.currencyToSymbol(formData.currency)} {formData.amount}</div>
            </Col>
          </Row>
          {renderProviderBankAccount()}
        </div>
      </div>
    )
  }
*/
  const existPaymentProviderType = (paymentProviderType) => {
    const paymentProviderCounter = paymentProvider ? paymentProvider.find((paymentProviderItem)=>paymentProviderItem.payment_provider_type===paymentProviderType) : null;
    if(paymentProviderCounter && paymentProviderCounter!==undefined) {
      return true;
    }
    return false;
  }

  const handleSelectedProvider = (paymentProviderType) => {
    const selectedProviderResult = paymentProvider ? paymentProvider.find((paymentProviderItem)=>paymentProviderItem.payment_provider_type===paymentProviderType) : null;
    setSelectedProvider(selectedProviderResult);
  }

  if(selectedProvider && selectedProvider.payment_provider_type==='bizum') {
    return renderProviderBizum();
  }

  if(selectedProvider && selectedProvider.payment_provider_type==='bank_account') {
    return renderProviderBankAccount();
  }

  return (
    <div className='w-100'>
      <div className='modal-header'>Pagar servicio</div>
      <div className='modal-content'>
        <Row>
          <Col>
            <Notification className='alert'>Asegúrese de NO compartir pantalla al momento que realiza el ingreso.</Notification>
          </Col>
        </Row>
        <Row className='mb-column'>
          <Col className='label'>
            Monto a abonar:
            <div className='payment-amount'>{JSLib.currencyToSymbol(formData.currency)} {formData.amount}</div>
          </Col>
        </Row>
        {(paymentProvider && existPaymentProviderType('bizum')) ? (
          <Row>
            <Col>
              <Button className='primary' label='Pagar con Bizum' action={()=>handleSelectedProvider('bizum')}/>
            </Col>
          </Row>
        ) : null }
        {(paymentProvider && existPaymentProviderType('bank_account')) ? (
          <Row>
            <Col>
              <Button className='primary' label='Pagar con Cuenta Bancaria' action={()=>handleSelectedProvider('bank_account')}/>
            </Col>
          </Row>
        ) : null }
        {/*<Row>
          <Col>
            <div>Pago por Tarjeta</div>
            <div><Button className='primary' action={()=>saveModal()} label='Pagar ahora'/></div>
          </Col>
        </Row>*/}
      </div>
      {/*<div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
      </div>*/}
    </div>
  )
}

export default PaymentCustomerModal;
