import { JS } from "grovefy-ui-pack";
import {request, ContentTypes} from '../store/api/api';
export const test = (name: String) => {
  return 0;
}

export const createToken = () => {
  var today = new Date();
  var dd = today.getDate();

  var mm = today.getMonth()+1; 
  var yyyy = today.getFullYear();
  var time = today.getTime();
  if(dd<10) {
    dd='0'+dd;
  } 

  if(mm<10) {
    mm='0'+mm;
  } 
  today = yyyy+'-'+mm+'-'+dd+'-'+time;
  //return today;
  return time;
}

export const getURLParam = (name: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(name);
}

export const amountToStripeFormat = (amount:any) => {
  let amount_converted = amount.toString();
  if (amount_converted.indexOf(',') === -1) {
    amount_converted = amount_converted+'00';
    return amount_converted;
  }
  const amount_array = amount_converted.split(',');
  const amount_integer = amount_array[0];
  const amount_decimal = amount_array[1].length>1 ? amount_array[1]: amount_array[1]+'0';
  return amount_integer+amount_decimal;
}

export const grovefyClear = () => {
  const data = {
    function: 'clear',
    parameters: { 
      'message': ''
    }
  };
  grovefy.send(data);
}

export const timestampToDate = (dateStripe:any) => {
  const dateResult = new Date(dateStripe * 1000);  
  const dateLocate = dateResult.toLocaleString();
  const result = dateLocate.substring(0,dateLocate.length-3);
  return result.replace(',', '');
}

export const getTimestampNow = () => {
  const date = new Date().getTime();
  return Math.floor(date/1000);
}

export const stripeAmountToDecimal = (amountStripe:any) => {
  const amount = amountStripe.toString()
  const integer = amount.substring(0, amount.length-2) ? amount.substring(0, amount.length-2): 0;
  const decimal = amount.substring(amount.length-2, amount.length);
  const result = integer+','+decimal;
  return result;
}

export const stripeCurrency = (currency:any) => {
  const currency_lowercase = currency.toLowerCase();
  switch (currency_lowercase) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    default:
      return '-';
  }
}

export const tierPrices = () => {
  const prices_tag = ['starter', 'professional', 'company']
  const prices = prices_tag.map((item)=> {
    return getTier(item);
  });
  return prices;
}

export const getTier = (account_tier:any) => {

  switch (account_tier) {
    case 'starter':
      return {
        tier_id: 'starter',
        tier_name: 'Starter',
        tier_description: 'Para individuos que comienzan con sus primeros clientes.',
        tier_features: [
          'Límite 100 llamadas mes',
          // 'Pasarela de pagos (5% + 0.50€ por pago que recibes)',
          'Soporte'
        ],
        tier_price: '1200'
      };
    case 'professional':
      return {
        tier_id: 'professional',
        tier_name: 'Professional',
        tier_description: 'Para negocios que cuentan con flujo constante de clientes.',
        tier_features: [
          'Límite 500 llamadas mes',
          //'Pasarela de pagos (5% + 0.50€ por pago que recibes)',
          'Soporte'
        ],
        tier_price: '1800'
      };
    case 'company':
      return {
        tier_id: 'company',
        tier_name: 'Company',
        tier_description: 'Para servicios de venta y soporte de grandes empresas.',
        tier_features: [
          'Llamadas ilimitadas por mes',
          //'Pasarela de pagos (5% + 0.50€ por pago que recibes)',
          'Soporte'
        ],
        tier_price: '9000'
      };
    default:
      return '-';
  }
}

export function getMessagesCookie (){
  let sanitizeMessagesCookie:any = [];
  if(localStorage.getItem('messages')!==null){
    const messagesCookie = JSON.parse(JS.decodeString(localStorage.getItem('messages')));
    messagesCookie.forEach((messageItem:any, index:any) => {
      messageItem.component='';
      if (index>0) {
        if((messagesCookie[index-1].author===messagesCookie[index].author)&&(messagesCookie[index-1].message===messagesCookie[index].message)) {
          // console.log('nothing');
        } else {
          sanitizeMessagesCookie.push(messageItem);
        }
      } else {
        sanitizeMessagesCookie.push(messageItem);
      }
    })
  } else {
    sanitizeMessagesCookie = [];
  }
  return sanitizeMessagesCookie;
}

export const setMessagesCookie = (new_message: any) => {
  const messagesCookie = localStorage.getItem('messages')!==null ? JSON.parse(JS.decodeString(localStorage.getItem('messages'))): [];
  messagesCookie.push(new_message);
  localStorage.setItem('messages', JS.encodeString(JSON.stringify(messagesCookie)));
}

export const getTime = () => {
  const dateTime = new Date();
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, '0');
  const day = String(dateTime.getDate()).padStart(2, '0');
  const hours = String(dateTime.getHours()).padStart(2, '0');
  const minutes = String(dateTime.getMinutes()).padStart(2, '0');
  const customFormattedString = `${year}/${month}/${day} ${hours}:${minutes}`;
  return customFormattedString;
}

export const convertTime = (dateTime:string) => {
  const year = dateTime.substring(0,4);
  const month = dateTime.substring(5,7);
  const day = dateTime.substring(8,10);
  const time_start = dateTime.substring(10,16);
  const time_end = dateTime.substring(17,22);
  const customFormattedString = `${day}/${month}/${year} ${time_start} a ${time_end}hs`;
  return customFormattedString;
}

export const currencyToSymbol = (currency: string) => {
  switch (currency) {
    case 'EUR':
      return '€';
    default:
      return '$';
  }
}

export const getHost = () => {
  const host = window.location.host==='localhost:8081' ? 'http://localhost:8081' : 'https://'+window.location.host;
  return host;
}

export const getTokenToCustomer = (customer_token: string) => {
  if(customer_token.substring(0,9)==='customer_') {
    const token_filtered = customer_token.substring(9,customer_token.length);
    if(token_filtered.length>30) {
      const customer = JSON.parse(decodeToken(token_filtered));
      return `${customer.fullname}`
    } else {
      return `Cliente ${token_filtered}`
    }
  } else {
    // const customer = customer_token.substring(8, customer_token.length);
    // console.log('getTokenToCustomer: customer_token: ',customer)
    return 'Soporte';    
  }
}

export const encodeToken = (token: string) => {
  const tokenResult = JS.encodeString(token);
  const result = tokenResult.replaceAll('=','XTyhj');
  return result;
}

export const decodeToken = (token: string) => {
  const tokenReplaced = token.replaceAll('XTyhj','=');
  const result = JS.decodeString(tokenReplaced);
  return result;
}

export function dynamicSort(property:any) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a:any,b:any) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

  // Get branch related to the meet
  export async function getEndpointMessage(channel_id:string) {
    const payload = {
      "channel_id" : channel_id
    };
    const endpoint = `/message`;
    try {
      const res = await request('POST', endpoint, payload, { 'content-type': ContentTypes.json });
      const result = res.data.data;
      return result;
    } catch (err) {
      console.log(err)
      return [];
    }
  };

  export const getColor = (index: number) => {
    const colorList = ['color-1','color-2','color-3','color-4','color-5','color-6','color-7','color-8','color-9','color-0'];
    const last_digit = index % 10;
    return colorList[last_digit];
  }


  export const convertToLinks = (text: string) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
  
    return text.split(urlRegex).map((part, index) => {
      // Check if the part is a URL
      if (index % 2 === 1) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
  
      return part;
    });
  };