import React from 'react';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, H3 } from 'grovefy-ui-pack';

function Analyst() {
  const {t} = useTranslation('common');

  return (
    <div className='medium'>
      <Section maxWidth='medium'>
        <Row>
          <Col><H3>SEC Reporting and External Audit Analyst</H3></Col>
        </Row>
        <Row>
          <Col>New York, New York, US</Col>
        </Row>
        <Row>
          <Col>
            We are a digitally native technology services company where innovation, design and engineering meet scale. We use some of the latest technologies in the digital and cognitive field to empower organizations in every aspect.
          </Col>
        </Row>
        <Row>
          <Col>
            Right now, we are looking for a SEC Reporting and Audit, Manager to join our team at Rapimeet!
          </Col>
        </Row>
        <Row>
          <Col>
            <div>You will get the chance to:</div>
            <ul style={{marginLeft: 40}}>
              <li>Work with professionals who have created some of the most revolutionary solutions in their fields.</li>
              <li>Work with our leaders, aligning the strategies to the company hierarchy, the personnel infrastructure and the way devs grow in the organization.</li>
              <li>Work with an international team.</li>
              <li>Connect culture initiatives with business impact.</li>
              <li>Seat with decision makers and drive educated facts based conversations at Rapimeet and the organization.</li>
              <li>Interact with the top management of many different organizations.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>What will help you succeed:</div>
            <ul style={{marginLeft: 40}}>
              <li>Graduate degree in Accounting.</li>
              <li>Previously experience working external audit services, in B4 (5/6 years)</li>
              <li>Being flexible & goal oriented.</li>
              <li>Being capable of taking a step back to look at a bigger picture.</li>
              <li>Being able to work independently and under pressure or tight schedules.</li>
              <li>Knowledge of local and international standards for the review of financial statements.</li>
              <li>Enjoy analyzing and solving problems.</li>
              <li>Having good communications skills.</li>
              <li>Being organized and setting plans. </li>
              <li>Advanced English skills.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            At Rapimeet we believe that an inclusive culture and a diverse environment makes us stronger. We encourage people to have an inclusive spirit as our global footprint expands. We seek to generate a place of inspiration and growth for everyone. A safe space, based on equity as a value, where everyone's careers can be promoted and developed in the same way. There is no innovation without diversity and there is no improvement without plurality. 
          </Col>
        </Row>
        <Row>
          <Col>
            <a href='to: jobs@rapimeet.com'>jobs@rapimeet.com</a>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Analyst;